import { CurrenciesEnum, IStaticAllowedCurrencies } from '@nx-next-app/types'

const { MYR, THB, CNY, IDR, INR, KHM, BDT, VND, USD, USDT, KRW, BRL } =
	CurrenciesEnum

export const ALLOWED_CURRENCIES: IStaticAllowedCurrencies = {
	VIP: [MYR, THB, IDR, VND, KRW, BRL],
	AFFILIATE: [MYR, THB, IDR, CNY, BDT, USD, INR, VND, KHM, KRW, BRL],
	EXCHANGE: [INR, BDT, USD],
	REWARDS: [MYR, THB, IDR],
	LEADER_BOARD: [MYR, IDR, THB, VND],
	APP_DOWNLOAD: [MYR, USD, THB, CNY, IDR, VND, INR, KHM, USDT, KRW, BRL],

	TRANSFER: [MYR, INR, BDT, USD],
	BANK_INFO: [MYR, THB, IDR, INR, KHM, BDT, CNY, VND, KRW, BRL],

	GAMES_MPOKER: [THB],

	JACKPOT: [MYR, USD, THB, CNY, IDR, VND, INR, KHM, USDT],

	SPORTS_CRICKET: [BDT, INR, KRW, BRL],
	SPORTS_VOLLEYBALL: [MYR, THB, IDR, USD, CNY, KHM, KRW, BRL],
	// 市場反應 CNY 跟 USD 一直輸錢
	SPORTS_SABA_CLUB: [MYR, THB, IDR, INR, KHM, BDT, VND, USDT, KRW, BRL],
	// 市場反應 TFG 一直輸錢，CNY 跟 USD 先關起來
	ESPORT_TF_GAMING: [MYR, THB, IDR, INR, KHM, BDT, VND, USDT],

	LIVE_CASINO_PP: [MYR, THB, CNY, IDR, KHM, BDT, VND, USD, KRW, BRL],
	LIVE_CASINO_WM: [MYR, USD, THB, CNY, IDR, INR, KHM, VND],
	LIVE_CASINO_ALL_BET: [MYR, THB, IDR, VND, KRW, BRL],
	LIVE_CASINO_AG: [MYR, IDR, THB, CNY, USD, KHM, INR, VND],
	LIVE_CASINO_N2: [THB, CNY, IDR, INR, KHM, BDT, VND, USD],
	LIVE_SKY_WIND: [MYR, THB, CNY, IDR, INR, VND],
	LIVE_CASINO_PT: [MYR, IDR, THB, CNY, USD, KHM, INR, VND, KRW, BRL],
	LIVE_CASINO_MG: [MYR, THB, IDR, BDT, USD, INR, VND, KHM, KRW, BRL],
	LIVE_CASINO_SA: [MYR, THB, IDR, BDT, USD, INR, VND, KHM, BRL],
	LIVE_CASINO_BG: [MYR, THB, CNY, IDR, INR, KHM, BDT, VND, USD, USDT, KRW, BRL],
	LIVE_CASINO_EVO: [MYR, THB, CNY, IDR, INR, KHM, BDT, VND, USD, USDT, KRW],

	ESPORT_IM_E_SPORT: [MYR, THB, IDR, INR, BDT, VND, KHM, KRW, BRL],

	SLOTS_PP: [MYR, THB, CNY, IDR, KHM, BDT, VND, USD, KRW, BRL],
	SLOTS_AMB: [THB, USD, CNY, KHM],
	SLOTS_FA_CHAI: [MYR, THB, IDR, INR, BDT, USD, VND, KRW, BRL],
	SLOTS_PG: [IDR, THB, BDT, CNY, USD, KHM, INR, VND],
	SLOTS_PEGASUS: [MYR],
	SLOTS_SKY_WIND: [MYR, THB, CNY, IDR, INR, VND],
	SLOTS_JOKER: [MYR, THB, KHM, IDR, INR, BDT, CNY, USD, KRW, BRL],
	SLOTS_PT: [MYR, THB, IDR, CNY, USD, INR, VND, KHM, KRW, BRL],
	SLOTS_MGPSW: [MYR, THB, IDR, BDT, USD, KHM, INR, VND, KRW, BRL],
	SLOTS_SIMPLE_PLAY: [MYR, THB, IDR, BDT, USD, KHM, INR, VND, BRL],
	SLOTS_SG: [MYR, THB, CNY, IDR, INR, KHM, BDT, VND, USD, USDT, KRW, BRL],
	SLOTS_AMIGO: [MYR, THB, CNY, IDR, INR, KHM, BDT, VND, USD, USDT],
	SLOTS_ES: [MYR, THB, CNY, IDR, INR, KHM, BDT, VND, USD, USDT, KRW],
	SLOTS_ADVANT_PLAY: [MYR, THB, CNY, IDR, INR, KHM, BDT, VND, USD, USDT, KRW],
	SLOTS_BNG: [MYR, THB, CNY, IDR, INR, KHM, BDT, VND, USD, USDT, KRW],
	SLOTS_JILI: [MYR, THB, CNY, IDR, INR, KHM, BDT, VND, USD, USDT, KRW],
	SLOTS_NO_LIMIT_CITY: [MYR, THB, CNY, IDR, INR, KHM, BDT, VND, USD, USDT, KRW],
	SLOTS_RED_TIGER: [MYR, THB, CNY, IDR, INR, KHM, BDT, VND, USD, USDT, KRW],
	SLOTS_NET_ENT: [MYR, THB, CNY, IDR, INR, KHM, BDT, VND, USD, USDT, KRW],

	GAMES_V8_POKER: [MYR, USD, THB, IDR, INR, CNY, KHM, VND, USDT, KRW, BRL],
	GAMES_POKER_M_POKER: [THB],
	GAMES_POKER_AMB_POKER: [THB],
	GAME_SPACEMAN: [MYR, IDR, THB, BDT, CNY, USD, KHM, VND, KRW, BRL],
	GAME_AVIATOR: [MYR, THB, CNY, IDR, INR, KHM, VND, USD, KRW, BRL],
	GAME_MINES: [MYR, THB, CNY, IDR, INR, KHM, VND, USD, KRW, BRL],
	GAME_GOAL: [MYR, THB, CNY, IDR, INR, KHM, VND, USD, KRW, BRL],
	GAME_DICE: [MYR, THB, CNY, IDR, INR, KHM, VND, USD, KRW, BRL],
	GAMES_MAX_GAME: [MYR, THB, CNY, IDR, INR, KHM, BDT, VND, USD, USDT],
	GAMES_93_CONNECT: [MYR, THB, CNY, IDR, INR, KHM, BDT, VND, USD, USDT],
	GAME_CRASH: [MYR, THB, CNY, IDR, INR, KHM, BDT, VND, USD, USDT, KRW],
	GAME_GORUSH: [MYR, THB, CNY, IDR, INR, KHM, BDT, VND, USD, USDT, KRW],

	FISHING_SG: [INR, VND],
	FISHING_CQ9_ONE_SHOT_FISHING: [INR, VND, KRW, BRL],
	FISHING_HUNTING_HAPPY_FISH_5: [
		MYR,
		THB,
		KHM,
		IDR,
		INR,
		BDT,
		CNY,
		USD,
		KRW,
		BRL,
	],
	FISHING_HUNTING_MONSTER_AWAKEN: [
		MYR,
		THB,
		KHM,
		IDR,
		INR,
		BDT,
		CNY,
		USD,
		KRW,
		BRL,
	],
	FISHING_HAIBA: [MYR, THB, KHM, IDR, INR, BDT, CNY, USD, KRW, BRL],
	FISHING_SGW: [MYR, THB, CNY, IDR, INR, KHM, BDT, VND, USD, USDT],
	FISHING_ZOMBIE_PARTY: [MYR, THB, CNY, IDR, INR, KHM, BDT, VND, USD, USDT],
	FISHING_ALIEN_HUNTER: [MYR, THB, CNY, IDR, INR, KHM, BDT, VND, USD, USDT],

	FOUR_D_GRAND_DRAGON: [MYR],

	LOTTERY_GPI_THAILOTTERY: [THB],
	LOTTERY_GPI_FAST3: [MYR, THB, KHM, IDR, INR, BDT, CNY, USD, KRW, BRL],
	LOTTERY_TCG: [MYR, THB, CNY, IDR, INR, KHM, BDT, VND, USD, USDT],

	// === Profile method ===
	ADDRESS: [MYR, USD, THB, CNY, IDR, VND, INR, KHM, BDT, USDT],

	// Deposit
	RECEIPT: [THB],

	// Home
	LIVE_STREAMING_LINK: [MYR, THB, VND],
	LUCKY_WHEEL: [MYR, THB, IDR],

	// Euro-Cup
	Euro_Cup: [MYR, THB, CNY, IDR, INR, KHM, BDT, VND, USD, USDT],
	// Retention Floating Action Button (lucky wheel以外的幣別要顯示)
	Retention: [CNY, INR, KHM, BDT, VND, USD, USDT, KRW, BRL],

	// 奧運不准CNY USD
	OLYMPIC: [MYR, THB, IDR, INR, KHM, BDT, VND, USDT, KRW, BRL],
}
