import { DeviceTypeEnum } from '@nx-next-app/types'
import axios from 'axios'
import { GetServerSidePropsContext } from 'next'

declare module 'axios' {
	interface AxiosRequestConfig {
		log?: boolean
	}
}

export const baseUrl = '/api/'

// use in serverSide
export const baseAxios = axios.create({
	withCredentials: true,
	baseURL: `${process.env['NEXT_PUBLIC_ENV_DB_HOST']}${baseUrl}`,
	headers: {
		common: {
			'ai-host': `${process.env['NEXT_PUBLIC_REFERER_HOST']}`, // 預設 referrer
		},
	},
	timeout: 3000,
})

export function setServerSideHeaders(context: GetServerSidePropsContext): void {
	// 從 context 物件中解構出 req
	const { req } = context
	const userAgent = req.headers['user-agent']

	const ipHeaders = {
		'x-forwarded-for':
			req.headers['x-forwarded-for'] || req.socket?.remoteAddress,
		'X-Client-IP': req.headers['x-client-ip'],
		'cloudfront-viewer-address': req.headers['cloudfront-viewer-address'],
		'origin-host': `https://${req.headers.host}`,
		// headers added by CDN
		'n88-visitor-site': req.headers['n88-visitor-site'],
		'cdn-loop': req.headers['cdn-loop'],
		'cf-connecting-ip': req.headers['cf-connecting-ip'],
		'cf-ipcountry': req.headers['cf-ipcountry'],
		'cf-ray': req.headers['cf-ray'],
		'cf-visitor': req.headers['cf-visitor'],
		// LIFF requests have unique useragent (e.g. Line/14.6.3 LIFF)
		'ai-device': userAgent?.includes('LIFF')
			? DeviceTypeEnum.Liff
			: process.env['NEXT_PUBLIC_DEVICE_TYPE'],
	}

	// 為 baseAxios 實例設置預設 headers
	baseAxios.defaults.headers.common = {
		...baseAxios.defaults.headers.common,
		...ipHeaders,
	}
}

// 在 serverSideProps api request 添加 log ,在 serverSide api 中添加 {log : true} 屬性即可將 request 添加在 server log 中
baseAxios.interceptors.request.use(
	config => {
		if (config.log) {
			console.log('Sending request:', config)
		}
		return config
	},
	error => {
		return Promise.reject(error)
	}
)

export default baseAxios
