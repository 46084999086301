import { CurrenciesEnum } from '@nx-next-app/types'

export const FEATURE_BONUS: { [key: number]: string } = {
	[CurrenciesEnum.THB]: '2.0',
	[CurrenciesEnum.MYR]: '2.0',
	[CurrenciesEnum.IDR]: '2.0',
	[CurrenciesEnum.INR]: '1.8',
	[CurrenciesEnum.BDT]: '1.8',
	[CurrenciesEnum.CNY]: '1.8',
	[CurrenciesEnum.USD]: '1.8',
	[CurrenciesEnum.KHM]: '1.8',
}
