import { IAxiosResponse } from '../axiosBaseQuery'
import { rootApi } from '../rootApi/api'

import {
	ICustBankInfoData,
	ICustBonusWalletData,
	ICustSpecificBonusData,
	IDelMsgArgs,
	IEWalletData,
	IGetCustSpecificBonusArgs,
	IGetMsgContentArgs,
	IGetMsgListArgs,
	IGetMsgListResponse,
	IGetReferralArgs,
	IMainWalletInfoData,
	IMsgContentResponse,
	IPopUpMsgData,
	IReferralData,
	IReferralSettingData,
	IReferralSettingDetailData,
	IReferralSummaryData,
	ISetChangePasswordArgs,
	ISetLangArgs,
	ISetMsgReadArgs,
	ITpGameTypeData,
	ITpWalletData,
	ITpWalletInfoData,
	IWalletInfoData,
	IWithdrawalAvailableBalanceData,
	TpWalletListTypeEnum,
} from './type'

export const accountApiUrl = {
	getPopupMsg: 'Account/GetPopupMsg',
	getMsgCount: 'Account/getMsgCount',
	getMainWalletInfo: 'Account/GetWalletBalance',
	getCustBonusWallet: 'Account/GetCustBonusWallet',
	getTpWalletList: 'Account/GetTpWalletList',
	getTpWalletBalance: 'Account/GetTpWalletBalance',
	onlineCheck: 'Account/onlineCheck',
	setLang: 'Account/SetLanguage',
	setHideBalance: 'Account/SetHideBalance',
	getCustSpecificBonus: 'Account/GetCustSpecificBonus',
	changePassword: 'Account/ChangePassword',
	SetFavoriteGame: 'Account/SetFavoriteGame',
	getMessageList: 'Account/GetMsgList',
	delMsg: 'Account/DelMsg',
	setMsgRead: 'Account/SetMsgReadV2',
	getMsgContent: 'Account/GetMsgContent',
	getReferralSetting: 'Account/GetReferralSetting',
	getReferralCode: 'Account/GetReferralCode',
	getReferralSummary: 'Account/GetReferralSummaryV2',
	getReferralSettingDetail: 'Account/GetReferralSettingDetail',
	getWithdrawalAvailableBalance: 'Account/GetWithdrawalAvailableBalance',
	getCustEWalletInfo: 'Account/GetCustEWalletInfo',
	getReferral: 'Account/GetRefFriendDetail',
	getCustBankInfo: 'Account/GetCustBankInfo',
	getWalletTransferStatus: 'Account/GetWalletTransferStatus',
	setWalletTransferStatus: 'Account/SetWalletTransferStatus',
	getCustVIPProgress: 'Account/GetCustVIPProgress',
	getWalletInfo: 'Account/GetWalletInfo',
	getCustTpGameTypeSetting: 'Account/GetCustTpGameTypeSetting',
}

const accountApi = rootApi.injectEndpoints({
	endpoints: builder => ({
		onlineCheck: builder.query<IAxiosResponse<null>, null>({
			query: () => ({
				url: accountApiUrl.onlineCheck,
				method: 'POST',
			}),
		}),
		getMsgCount: builder.query<IAxiosResponse<number>, null>({
			query: () => ({
				url: accountApiUrl.getMsgCount,
				method: 'POST',
			}),
			providesTags: ['ACCOUNT_GET_MSG_COUNT'],
		}),
		getPopupMsg: builder.query<IAxiosResponse<IPopUpMsgData>, null>({
			query: () => ({
				url: accountApiUrl.getPopupMsg,
				method: 'POST',
			}),
			providesTags: ['ACCOUNT_POPUP_MSG'],
		}),
		getMainWalletInfo: builder.query<IAxiosResponse<IMainWalletInfoData>, null>(
			{
				query: () => ({
					url: accountApiUrl.getMainWalletInfo,
					method: 'POST',
				}),
				providesTags: ['ACCOUNT_GET_MAIN_WALLET_INFO'],
			}
		),
		getCustBonusWalletList: builder.query<
			IAxiosResponse<ICustBonusWalletData[]>,
			null
		>({
			query: () => ({
				url: accountApiUrl.getCustBonusWallet,
				method: 'POST',
			}),
		}),
		getTpWalletList: builder.query<
			IAxiosResponse<ITpWalletData[]>,
			{ langId: string; listType: TpWalletListTypeEnum }
		>({
			query: ({ langId, listType }) => ({
				url: `${accountApiUrl.getTpWalletList}/${langId}`,
				method: 'POST',
				data: { listType },
			}),
		}),
		getTpWalletInfoByTpId: builder.query<
			IAxiosResponse<ITpWalletInfoData>,
			number
		>({
			query: tpId => ({
				url: `${accountApiUrl.getTpWalletBalance}`,
				method: 'POST',
				data: { tpId },
			}),
		}),
		setLanguage: builder.mutation<IAxiosResponse<void>, ISetLangArgs>({
			query: data => ({
				url: accountApiUrl.setLang,
				method: 'POST',
				data,
			}),
			invalidatesTags: ['ACCOUNT_STRICT_GET_USER_INFO'],
		}),
		setHideBalance: builder.mutation<IAxiosResponse<void>, boolean>({
			query: isHide => ({
				url: `${accountApiUrl.setHideBalance}?value=${isHide}`,
				method: 'GET',
			}),
			invalidatesTags: ['ACCOUNT_GET_MAIN_WALLET_INFO'],
		}),
		getCustSpecificBonus: builder.query<
			IAxiosResponse<ICustSpecificBonusData>,
			IGetCustSpecificBonusArgs
		>({
			query: data => ({
				url: accountApiUrl.getCustSpecificBonus,
				method: 'POST',
				data,
			}),
		}),
		setChangePassword: builder.mutation<
			IAxiosResponse<void>,
			ISetChangePasswordArgs
		>({
			query: data => ({
				url: accountApiUrl.changePassword,
				method: 'POST',
				data,
			}),
			invalidatesTags: ['ACCOUNT_STRICT_GET_USER_INFO'],
		}),
		setFavoriteGame: builder.mutation<
			IAxiosResponse<void>,
			{ providerId: number; gameId: string; isRemove: boolean }
		>({
			query: data => ({
				url: accountApiUrl.SetFavoriteGame,
				method: 'POST',
				data,
			}),
			invalidatesTags: ['SLOTS_GAME_DATA'],
		}),
		getMsgList: builder.query<IGetMsgListResponse, IGetMsgListArgs>({
			query: ({ pageNumber, pageSize }) => ({
				url: accountApiUrl.getMessageList,
				method: 'POST',
				data: { pageNumber, pageSize },
			}),
			providesTags: ['ACCOUNT_GET_MSG'],
		}),
		delMsg: builder.mutation<IAxiosResponse<void>, IDelMsgArgs>({
			query: list => ({
				url: accountApiUrl.delMsg,
				method: 'POST',
				data: list,
			}),
			invalidatesTags: ['ACCOUNT_GET_MSG', 'ACCOUNT_GET_MSG_COUNT'],
		}),
		setMsgRead: builder.mutation<IAxiosResponse<null>, ISetMsgReadArgs>({
			query: list => ({
				url: accountApiUrl.setMsgRead,
				method: 'POST',
				data: list,
			}),
			invalidatesTags: ['ACCOUNT_GET_MSG', 'ACCOUNT_GET_MSG_COUNT'],
		}),
		// * 這裡為了要觸發 其他三隻 api 所以先用 mutation
		// * 打這支後端似乎會自動觸發 setMsgRead
		getMsgContent: builder.mutation<IMsgContentResponse, IGetMsgContentArgs>({
			query: list => ({
				url: accountApiUrl.getMsgContent,
				method: 'POST',
				data: list,
			}),
			invalidatesTags: [
				'ACCOUNT_POPUP_MSG',
				'ACCOUNT_GET_MSG_COUNT',
				'ACCOUNT_GET_MSG',
			],
		}),
		getReferralSetting: builder.query<
			IAxiosResponse<IReferralSettingData[]>,
			null
		>({
			query: () => ({
				url: accountApiUrl.getReferralSetting,
				method: 'POST',
			}),
		}),
		getReferralCode: builder.query<IAxiosResponse<string>, null>({
			query: () => ({
				url: accountApiUrl.getReferralCode,
				method: 'POST',
			}),
		}),
		getReferral: builder.query<
			IAxiosResponse<IReferralData[]>,
			IGetReferralArgs
		>({
			query: data => ({
				url: accountApiUrl.getReferral,
				method: 'POST',
				data,
			}),
		}),
		getReferralSummary: builder.query<
			IAxiosResponse<IReferralSummaryData>,
			null
		>({
			query: () => ({
				url: accountApiUrl.getReferralSummary,
				method: 'POST',
			}),
		}),
		getReferralSettingDetail: builder.query<
			IAxiosResponse<IReferralSettingDetailData>,
			null
		>({
			query: () => ({
				url: accountApiUrl.getReferralSettingDetail,
				method: 'POST',
			}),
		}),
		getWithdrawalAvailableBalance: builder.query<
			IAxiosResponse<IWithdrawalAvailableBalanceData>,
			null
		>({
			query: () => ({
				url: accountApiUrl.getWithdrawalAvailableBalance,
				method: 'POST',
			}),
			providesTags: ['WITHDRAWAL_API_POST'],
		}),
		getCustBankInfo: builder.query<IAxiosResponse<ICustBankInfoData>, void>({
			query: () => ({
				url: accountApiUrl.getCustBankInfo,
				method: 'POST',
			}),
			providesTags: [
				'WITHDRAWAL_BANKCARD_POST',
				'WITHDRAWAL_CRYPTO_POST',
				'ACCOUNT_GET_USER_BANK_INFO',
			],
		}),
		getCustEWalletInfo: builder.query<IAxiosResponse<IEWalletData[]>, null>({
			query: () => ({
				url: accountApiUrl.getCustEWalletInfo,
				method: 'POST',
			}),
			providesTags: [
				'WITHDRAWAL_EWALLET_POST',
				'ACCOUNT_GET_USER_EWALLET_INFO',
			],
		}),
		getWalletTransferStatus: builder.query<
			IAxiosResponse<{ data: number }>,
			void
		>({
			query: () => ({
				url: accountApiUrl.getWalletTransferStatus,
				method: 'POST',
			}),
			providesTags: ['ACCOUNT_GET_AUTO_TRANSFER_INFO'],
		}),
		setWalletTransferStatus: builder.mutation<
			IAxiosResponse<void>,
			{ autoTransfer: number }
		>({
			query: data => ({
				url: accountApiUrl.setWalletTransferStatus,
				method: 'POST',
				data,
			}),
			invalidatesTags: ['ACCOUNT_GET_AUTO_TRANSFER_INFO'],
		}),
		getCustVIPProgress: builder.query<
			IAxiosResponse<{
				currentVipLevel: number
				upDeposit: null | number
				actualDeposit: number
				downDeposit: null | number
				downTurnover: null | number
			}>,
			null
		>({
			query: () => ({
				url: accountApiUrl.getCustVIPProgress,
				method: 'POST',
			}),
		}),
		getWalletInfo: builder.query<IAxiosResponse<IWalletInfoData>, null>({
			query: () => ({
				url: accountApiUrl.getWalletInfo,
				method: 'POST',
			}),
		}),
		getCustTpGameTypeSetting: builder.query<
			IAxiosResponse<ITpGameTypeData[]>,
			null
		>({
			query: () => ({
				url: accountApiUrl.getCustTpGameTypeSetting,
				method: 'POST',
			}),
		}),
	}),
})

export const {
	useGetPopupMsgQuery,
	useGetMsgCountQuery,
	useGetMainWalletInfoQuery,
	useLazyGetMainWalletInfoQuery,
	useGetCustBonusWalletListQuery,
	useLazyOnlineCheckQuery,
	useLazyGetTpWalletInfoByTpIdQuery,
	useGetTpWalletListQuery,
	useSetLanguageMutation,
	useSetHideBalanceMutation,
	useLazyGetCustSpecificBonusQuery,
	useSetFavoriteGameMutation,
	useGetMsgListQuery,
	useDelMsgMutation,
	useSetMsgReadMutation,
	useGetMsgContentMutation,
	useGetReferralCodeQuery,
	useGetReferralSettingQuery,
	useGetWithdrawalAvailableBalanceQuery,
	useSetChangePasswordMutation,
	useGetReferralSummaryQuery,
	useLazyGetReferralSettingDetailQuery,
	useLazyGetReferralQuery,
	useGetReferralQuery,
	useGetCustBankInfoQuery,
	useGetCustEWalletInfoQuery,
	useGetWalletTransferStatusQuery,
	useSetWalletTransferStatusMutation,
	useGetCustVIPProgressQuery,
	useLazyGetWalletInfoQuery,
	useGetCustTpGameTypeSettingQuery,
} = accountApi
