import { paths } from '@nx-next-app/constants'
import { IMetaData } from '@nx-next-app/types'

export const META_DATA: IMetaData = {
	[paths.home.root]: {
		en: {
			title: `Nova88 Solutions | Online Betting | Demo Site`,
			description: `Nova88 is Most Trusted Sportsbook, Live Casino and Slot Games in Asia. Officially Licensed and Subsidiary of SABA Sports.`,
			keyword: `Asia Most Trusted Sportsbook, Live Casino, Slot Games, SABA Sports, Online Casino, Online Betting`,
			links: [
				{ rel: 'canonical', href: `${process.env['NEXT_PUBLIC_REFERER_HOST']}/en/` },
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88.net/en/`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/',
					hrefLang: 'bn-BD',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88103.net/kh/',
					hrefLang: 'km-KH',
				},
				{
					rel: 'alternate',
					href: 'http://nova88901.net/vn/',
					hrefLang: 'vi-VN',
				},
			],
		},
		cs: {
			title: `Nova88 欧亚体育 - 亚洲最佳体育博彩，真人娱乐与在线博彩。`,
			description: `Nova88 欧亚体育 -亚洲知名品牌与最受信任的在线博彩网站。立即注册并享受顶尖的体育博彩，真人娱乐，电子游戏老虎机，彩票，快乐彩，和捕鱼游戏。`,
			keyword: `Nova88 欧亚体育，Nova88 欧亚体育链接，在线博彩，在线赌博，马来西亚在线博彩，体育博彩，彩票，马来西亚真人娱乐场`,
			links: [
				{ rel: 'canonical', href: `https://www.nova88105.net/cs/` },
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88105.net/cs/`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/',
					hrefLang: 'bn-BD',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88103.net/kh/',
					hrefLang: 'km-KH',
				},
				{
					rel: 'alternate',
					href: 'http://nova88901.net/vn/',
					hrefLang: 'vi-VN',
				},
			],
		},
		id: {
			title: `Nova88 - Situs Judi Online - Bandar Taruhan Bola Terpercaya`,
			description: `Nova88 adalah Situs Online Terbaik Berlisensi Resmi dan Terpercaya dengan berbagai permainan Casino Online, Taruhan Bola dan Slot Mesin Online yang akan menghibur Anda.`,
			keyword: `Nova88, Nova88 Indo, Situs Bola Terpercaya, Situs Bola Lisensi Resmi, Bola Online, Slot Mesin Online, Casino Online`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88-indo805.info/id/`,
				},
				{
					rel: 'canonical',
					href: `https://www.nova88-indo805.info/id/`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/',
					hrefLang: 'bn-BD',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88103.net/kh/',
					hrefLang: 'km-KH',
				},
				{
					rel: 'alternate',
					href: 'http://nova88901.net/vn/',
					hrefLang: 'vi-VN',
				},
			],
		},
		th: {
			title: `Nova88 Solutions | เดิมพันออนไลน์ | เว็บไซต์สาธิต`,
			description: `Nova88  เป็นเว็บไซต์เดิมพันออนไลน์ที่หน้าเชื่อถือและดีสุดในเอเชีย เข้าร่วมและเดิมพันกีฬา,คาสิโนสด,สล็อต คาสิโน,ลอตเตอรี,คีโน,และ ตกปลา`,
			keyword: `Nova88, ลิงค์ Nova88, คาสิโนออนไลน์, การพนันออนไลน์, การพนันออนไลน์ไทย, การเดิมพันกีฬา, ลอตเตอรี, ไทยคาสิโนสด`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88bkk.com/th/`,
				},
				{ rel: 'canonical', href: `${process.env['NEXT_PUBLIC_REFERER_HOST']}/th/` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/',
					hrefLang: 'bn-BD',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88103.net/kh/',
					hrefLang: 'km-KH',
				},
				{
					rel: 'alternate',
					href: 'http://nova88901.net/vn/',
					hrefLang: 'vi-VN',
				},
			],
		},
		my: {
			title: `Nova88 | Nova88 Slot | Nova88 Casino | Nova88 Asia`,
			description: `Nova88 is Most Trusted Sportsbook, Live Casino and Slot Games in Asia. Officially Licensed and Subsidiary of SABA Sports.`,
			keyword: `Nova88, Nova88 link, online casino, online gambling, online gambling malaysia, sports betting, lottery, live casino malaysia`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88mas.com/my/`,
				},
				{ rel: 'canonical', href: `https://www.nova88mas.com/my/` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/',
					hrefLang: 'bn-BD',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88103.net/kh/',
					hrefLang: 'km-KH',
				},
				{
					rel: 'alternate',
					href: 'http://nova88901.net/vn/',
					hrefLang: 'vi-VN',
				},
			],
		},
		hi: {
			title: `Nova88 | Nova88 Slot | Nova88 Casino | Nova88 Asia`,
			description: `Nova88 is Most Trusted Sportsbook, Live Casino and Slot Games in Asia. Officially Licensed and Subsidiary of SABA Sports.`,
			keyword: `Nova88, Nova88 link, online casino, online gambling, online gambling malaysia, sports betting, lottery, live casino malaysia`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88india.com/hi/`,
				},
				{ rel: 'canonical', href: `https://www.nova88india.com/hi/` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/',
					hrefLang: 'bn-BD',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88103.net/kh/',
					hrefLang: 'km-KH',
				},
				{
					rel: 'alternate',
					href: 'http://nova88901.net/vn/',
					hrefLang: 'vi-VN',
				},
			],
		},
		bd: {
			title: `Nova88 | Nova88 Slot | Nova88 Casino | Nova88 Asia`,
			description: `Nova88 is Most Trusted Sportsbook, Live Casino and Slot Games in Asia. Officially Licensed and Subsidiary of SABA Sports.`,
			keyword: `Nova88, Nova88 link, online casino, online gambling, online gambling malaysia, sports betting, lottery, live casino malaysia`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88106.net/bd/`,
				},
				{ rel: 'canonical', href: `https://www.nova88106.net/bd/` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/',
					hrefLang: 'bn-BD',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88103.net/kh/',
					hrefLang: 'km-KH',
				},
				{
					rel: 'alternate',
					href: 'http://nova88901.net/vn/',
					hrefLang: 'vi-VN',
				},
			],
		},
		kh: {
			title: `Nova88 | Nova88 Slot | Nova88 Casino | Nova88 Asia`,
			description: `Nova88 is Most Trusted Sportsbook, Live Casino and Slot Games in Asia. Officially Licensed and Subsidiary of SABA Sports.`,
			keyword: `Nova88, Nova88 link, online casino, online gambling, online gambling malaysia, sports betting, lottery, live casino malaysia`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88106.net/kh/`,
				},
				{ rel: 'canonical', href: `https://www.nova88106.net/kh/` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/',
					hrefLang: 'bn-BD',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88103.net/kh/',
					hrefLang: 'km-KH',
				},
				{
					rel: 'alternate',
					href: 'http://nova88901.net/vn/',
					hrefLang: 'vi-VN',
				},
			],
		},
		vn: {
			title: `Nova88 | Nova88 quay hũ | Nova88 Casino | Nova88 Asia`,
			description: `Nova88 là nhà cái đáng tin cậy cung cấp dịch vụ cá độ thể thao, live casino, và quay hũ tại châu Á. Là đối tác chính thức của SABA Sports.`,
			keyword: `Nhà cái hàng đầu châu Á, Live Casino, Quay hũ, SABA Sports, Online Casino, cá độ trực tuyến`,
			links: [
				{ rel: 'canonical', href: `https://www.nova88.net/en/` },
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88.net/en/`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/',
					hrefLang: 'bn-BD',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88103.net/kh/',
					hrefLang: 'km-KH',
				},
				{
					rel: 'alternate',
					href: 'http://nova88901.net/vn/',
					hrefLang: 'vi-VN',
				},
			],
		},
		ko: {
			title: `Nova88 | 한국에서 신뢰할 수 있는 온라인 카지노`,
			description: `Nova88은(는) 아시아에서 가장 신뢰받는 스포츠북, 라이브 카지노 및 슬롯 게임입니다. SABA Sports의 공식 라이센스를 보유한 자회사입니다.`,
			keyword: `Nova88, 한국에서 가장 신뢰받는 스포츠 북, 라이브 카지노, 슬롯 게임, SABA Sports, 온라인 카지노 한국, 온라인 베팅, Nova88 로그인`,
			links: [
				{ rel: 'canonical', href: `https://www.nova88.net/ko/` },
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88.net/ko/`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/',
					hrefLang: 'bn-BD',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88103.net/kh/',
					hrefLang: 'km-KH',
				},
				{
					rel: 'alternate',
					href: 'http://nova88901.net/vn/',
					hrefLang: 'vi-VN',
				},
			],
		},
		br: {
			title: `Jogue no Nova88| o Cassino Online Confiável Favorito da América do Sul`,
			description: `Nova88 é o Sportsbook, Cassino Ao Vivo e Jogos de Caça-Níqueis mais confiável da América do Sul. Licenciado oficialmente e subsidiária da SABA Sports.`,
			keyword: `Jogue no Nova88, o Sportsbook, Cassino Ao Vivo, e Jogos de Caça-Níqueis mais confiável do Japão. Subsidiária oficial da SABA Sports. Cassino Online Japão. Apostas Online. Nova88 Login`,
			links: [
				{ rel: 'canonical', href: `https://www.nova88.net/br/` },
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88.net/en/`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/',
					hrefLang: 'bn-BD',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88103.net/kh/',
					hrefLang: 'km-KH',
				},
				{
					rel: 'alternate',
					href: 'http://nova88901.net/vn/',
					hrefLang: 'vi-VN',
				},
			],
		},
	},
	[paths.join.root]: {
		en: {
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88.net/en/join`,
				},
				{ rel: 'canonical', href: `https://www.nova88.net/en/join` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/join',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/join',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/join',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/join',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/join',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/join',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/join',
					hrefLang: 'bn-BD',
				},
			],
		},
		cs: {
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88105.net/cs/join`,
				},
				{ rel: 'canonical', href: `https://www.nova88105.net/cs/join` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/join',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/join',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/join',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/join',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/join',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/join',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/join',
					hrefLang: 'bn-BD',
				},
			],
		},
		th: {
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88bkk.com/th/join`,
				},
				{ rel: 'canonical', href: `https://www.nova88bkk.com/th/join` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/join',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/join',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/join',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/join',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/join',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/join',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/join',
					hrefLang: 'bn-BD',
				},
			],
		},
		id: {
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88-indo805.info/id/join`,
				},
				{
					rel: 'canonical',
					href: `https://www.nova88-indo805.info/id/join`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/join',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/join',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/join',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/join',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/join',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/join',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/join',
					hrefLang: 'bn-BD',
				},
			],
		},
		kh: {
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88-indo805.info/kh/join`,
				},
				{
					rel: 'canonical',
					href: `https://www.nova88-indo805.info/kh/join`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/join',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/join',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/join',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/join',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/join',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/join',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/join',
					hrefLang: 'bn-BD',
				},
			],
		},
		hi: {
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88-indo805.info/hi/join`,
				},
				{
					rel: 'canonical',
					href: `https://www.nova88-indo805.info/hi/join`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/join',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/join',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/join',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/join',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/join',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/join',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/join',
					hrefLang: 'bn-BD',
				},
			],
		},
		bd: {
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88-indo805.info/bd/join`,
				},
				{
					rel: 'canonical',
					href: `https://www.nova88-indo805.info/bd/join`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/join',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/join',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/join',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/join',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/join',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/join',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/join',
					hrefLang: 'bn-BD',
				},
			],
		},
		vn: {
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88-indo805.info/vn/join`,
				},
				{
					rel: 'canonical',
					href: `https://www.nova88-indo805.info/vn/join`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/join',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/join',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/join',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/join',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/join',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/join',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/join',
					hrefLang: 'bn-BD',
				},
			],
		},
		ko: {
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88-indo805.info/ko/join`,
				},
				{
					rel: 'canonical',
					href: `https://www.nova88-indo805.info/ko/join`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/join',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/join',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/join',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/join',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/join',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/join',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/join',
					hrefLang: 'bn-BD',
				},
			],
		},
		br: {
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88-indo805.info/br/join`,
				},
				{
					rel: 'canonical',
					href: `https://www.nova88-indo805.info/br/join`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/join',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/join',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/join',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/join',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/join',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/join',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/join',
					hrefLang: 'bn-BD',
				},
			],
		},
	},
	[paths.promotion.root]: {
		en: {
			title: `Nova88 Promotion | Trusted Live Casino | Welcome Bonus`,
			description: `Looking For The Best Live Casino Bonuses and Rewards? Don't Miss Out Our Monthly Exclusive Promotions! Play And Earn More Bonus With Nova88 now!`,
			keyword: `Online Casino Promotion, Welcome Bonus, Reload Bonus, Cash Rebate, Weekly Cashback, Withdrawal Bonus, Referral Rewards, Birthday Reward, New Member Promotion, Sportsbook Promotion, Casino Promotion, Slot Promotion`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88.net/en/promotion`,
				},
				{
					rel: 'canonical',
					href: `https://www.nova88.net/en/promotion`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/promotion',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/promotion',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/promotion',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/promotion',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/promotion',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/promotion',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/promotion',
					hrefLang: 'bn-BD',
				},
			],
		},
		cs: {
			title: `Nova88欧亚体育 - 在线博彩优惠活动`,
			description: `Nova88欧亚体育 - 优惠活动提高您获胜的机率。从首存红利，现金返水，再存红利与各种为您量身定制的优惠促销，马上领取！`,
			keyword: `Nova88欧亚体育，Nova88欧亚体育优惠活动，首存红利，存款优惠，在线博彩优惠，再存红利，现金反水，电子游戏老虎机优惠，免费金额`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88105.net/cs/promotion`,
				},
				{
					rel: 'canonical',
					href: `https://www.nova88105.net/cs/promotion`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/promotion',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/promotion',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/promotion',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/promotion',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/promotion',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/promotion',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/promotion',
					hrefLang: 'bn-BD',
				},
			],
		},
		th: {
			title: `Nova88 - โปรโมชั่นคาสิโนออนไลน์`,
			description: `Nova88 เพิ่มโอกาสในการชนะของคุณ ด้วยโบนัสต้อนรับ, โบนัสคืนเงิน, โบนัสฝากเงินซ้ำ, และโบนัสพิเศษที่เหมาะสำหรับคุณ  เข้าร่วมตอนนี้!`,
			keyword: `Nova88, โปรโมชั่น Nova88, โบนัสต้อนรับ, โบนัสเงินฝาก, โบนัสคาสิโนออนไลน์, โบนัสฝากเงินซ้ำ, โบนัสสล็อต, ฟรีเครดิต`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88bkk.com/th/promotion`,
				},
				{
					rel: 'canonical',
					href: `https://www.nova88bkk.com/th/promotion`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/promotion',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/promotion',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/promotion',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/promotion',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/promotion',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/promotion',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/promotion',
					hrefLang: 'bn-BD',
				},
			],
		},
		id: {
			title: `Nova88 Indonesia Promosi Kasino Online`,
			description: `Nova88 menawarkan Bonus untuk meningkatkan peluang menang Anda, menawarkan welcome bonus, rebate tunai, bonus re-deposit, dan bonus lainnya. Klaim sekarang!`,
			keyword: `Nova88, Nova88 Promosi, Welcome Bonus Nova88, Bonus Deposit, Bonus Reload, Bonus ReDepo, Bonus Redeposit, bonus slots, bonus slot mesin, bonus rebate, bonus cashback`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88-indo805.info/id/promotion`,
				},
				{
					rel: 'canonical',
					href: `https://www.nova88-indo805.info/id/promotion`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/promotion',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/promotion',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/promotion',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/promotion',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/promotion',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/promotion',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/promotion',
					hrefLang: 'bn-BD',
				},
			],
		},
		kh: {
			title: `ប្រូម៉ូសិន Nova88 |  កាស៊ីណូផ្សាយផ្ទាល់គួរឱ្យទុកចិត្ត |  ប្រាក់រង្វាន់ស្វាគមន៍`,
			description: `កំពុងស្វែងរកប្រាក់រង្វាន់ និងរង្វាន់កាស៊ីណូផ្សាយផ្ទាល់ល្អបំផុតមែនទេ?  កុំអោយរំលងប្រូម៉ូសិនពិសេសប្រចាំខែរបស់យើង! លេង និងទទួលបានប្រាក់រង្វាន់បន្ថែមជាមួយ Nova88 ឥឡូវនេះ!`,
			keyword: `ការប្រម៉ូសិនកាស៊ីណូអនឡាញ, ប្រាក់រង្វាន់ស្វាគមន៍, ប្រាក់រង្វាន់បន្ថែម, ប្រាក់បង្វិលសងវិញ, ប្រាក់រង្វាន់ប្រចាំសប្តាហ៍,ការដកប្រាក់, រង្វាន់ណែនាំ, រង្វាន់ថ្ងៃកំណើត, ការប្រម៉ូសិនសមាជិកថ្មី, ការប្រម៉ូសិនកញ្ចប់កីឡា, ការប្រម៉ូសិនកាស៊ីណូ, ការប្រម៉ូសិន Slot`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88.net/kh/promotion`,
				},
				{
					rel: 'canonical',
					href: `https://www.nova88.net/kh/promotion`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/promotion',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/promotion',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/promotion',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/promotion',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/promotion',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/promotion',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/promotion',
					hrefLang: 'bn-BD',
				},
			],
		},
		hi: {
			title: `Nova88 प्रमोशन | विश्वसनीय लाइव कैसीनो | वेलकम बोनस`,
			description: `सर्वश्रेष्ठ लाइव कैसिनो बोनस और पुरस्कार की तलाश में हैं? हमारे मासिक एक्सक्लूसिव  प्रचारों को देखना न भूलें! Nova88 के साथ अभी खेलें और अधिक बोनस कमाएँ!`,
			keyword: `ऑनलाइन कैसिनो प्रमोशन, वेलकम बोनस, रीलोड बोनस, कैश रिबेट, वीकली कैशबैक, विदड्रॉल बोनस, रेफरल रिवार्ड्स, बर्थडे रिवॉर्ड, न्यू मेंबर प्रमोशन, स्पोर्ट्सबुक प्रमोशन, कैसिनो प्रमोशन, स्लॉट प्रमोशन`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88.net/hi/promotion`,
				},
				{
					rel: 'canonical',
					href: `https://www.nova88.net/hi/promotion`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/promotion',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/promotion',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/promotion',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/promotion',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/promotion',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/promotion',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/promotion',
					hrefLang: 'bn-BD',
				},
			],
		},
		bd: {
			title: `নোভা ৮৮ প্রচার, সরাসরি বিশ্বস্ত ক্যাসিনো, স্বাগতম বোনাস `,
			description: `সেরা লাইভ ক্যাসিনো বোনাস এবং পুরষ্কার খুঁজছেন? আমাদের মাসিক আকর্ষনিয় প্রচারগুলি মিস করবেন না! এখনই Nova88 দিয়ে খেলুন এবং আরও বোনাস উপার্জন করুন`,
			keyword: `অনলাইন ক্যাসিনো প্রচার, স্বাগতম বোনাস, পুনরায় লোড বোনাস, নগদ ছাড়, সাপ্তাহিক ক্যাশব্যাক, উত্তোলন বোনাস, রেফারেল পুরস্কার, জন্মদিনের পুরস্কার, নতুন সদস্য প্রচার, স্পোর্টসবুক প্রচার, ক্যাসিনো প্রচার, স্লট প্রচার`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88.net/bd/promotion`,
				},
				{
					rel: 'canonical',
					href: `https://www.nova88.net/bd/promotion`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/promotion',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/promotion',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/promotion',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/promotion',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/promotion',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/promotion',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/promotion',
					hrefLang: 'bn-BD',
				},
			],
		},
		vn: {
			title: `Khuyến mãi Nova88 | Live Casino xanh chín | Thưởng chào mừng`,
			description: `Nhà cái nào có thưởng Live Casino lớn nhất? Đừng bỏ lỡ khuyến mãi đặc biệt hàng tháng của chúng tôi! Chơi càng hăng, thưởng càng đã với Nova88! `,
			keyword: `Khuyến mãi casino trực tuyến, thưởng chào mừng, hoàn trả tiền mặt, hoàn tiền mỗi tuần, thưởng rút, hoa hồng giới thiệu bạn bè, mừng sinh nhật, khuyến mãi tân thủ, khuyến mãi thể thao, khuyến mãi sòng bài, khuyến mãi quay hũ`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88.net/vn/promotion`,
				},
				{
					rel: 'canonical',
					href: `https://www.nova88.net/vn/promotion`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/promotion',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/promotion',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/promotion',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/promotion',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/promotion',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/promotion',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/promotion',
					hrefLang: 'bn-BD',
				},
			],
		},
		ko: {
			title: `Nova88 프로모션 | 무료 스핀 | 환영 보너스`,
			description: `매월 업데이트되는 프로모션을 놓치지 마세요. 오늘 Nova88에 가입하고 독점적인 환영 보너스를 받아보세요!`,
			keyword: `온라인 카지노 프로모션, 환영 보너스, 캐시 리베이트, 주간 캐시백, 추천 보너스, 생일 보너스, 신규 회원 프로모션, 보너스`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88.net/ko/promotion`,
				},
				{
					rel: 'canonical',
					href: `https://www.nova88.net/ko/promotion`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/promotion',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/promotion',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/promotion',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/promotion',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/promotion',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/promotion',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/promotion',
					hrefLang: 'bn-BD',
				},
			],
		},
		br: {
			title: `Promoções Nova88 | Giros Grátis | Bônus de Boas-Vindas`,
			description: `Não perca nossas promoções mensais atualizadas. Junte-se à Nova88 hoje e ganhe seus bônus de boas-vindas exclusivos!`,
			keyword: `Promoções de Cassino Online, Bônus de Boas-Vindas, Cashback, Reembolso em Dinheiro, Recompensas Semanais, Recompensas de Indicação, Recompensas de Aniversário, Promoção para Novos Membros, Bônus`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88.net/br/promotion`,
				},
				{
					rel: 'canonical',
					href: `https://www.nova88.net/br/promotion`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/promotion',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/promotion',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/promotion',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/promotion',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/promotion',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/promotion',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/promotion',
					hrefLang: 'bn-BD',
				},
			],
		},
	},
	[paths.vip.root]: {
		en: {
			title: `Nova88 VIP Program | VIP Member | Exclusive Rewards`,
			description: `Become Nova88 Exclusive VIP Today To Enjoy The Benefits And privileges With Difference Membership Tier. Play More, Rewards More. Hurry, We Are Waiting For You!`,
			keyword: `Nova88, Casino VIP Program, VIP privileges, VIP Exclusive Reward, Nova Club, Nova VIP Exclusive, Tier Upgrade Bonus, VIP Special Rebate, VIP Bonus, VIP Member Benefits`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88.net/en/vip`,
				},
				{ rel: 'canonical', href: `https://www.nova88.net/en/vip` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/vip',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/vip',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/vip',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/vip',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/vip',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/vip',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/vip',
					hrefLang: 'bn-BD',
				},
			],
		},
		th: {
			title: `โปรแกรม VIP Nova88 | ลูกค้า VIP | รางวัล`,
			description: `มาเป็น VIP พิเศษของ Nova88 วันนี้เพื่อรับสิทธิประโยชน์และสิทธิพิเศษด้วยระดับสมาชิกที่แตกต่างกัน เล่นมากขึ้น รางวัลมากขึ้น เร็วเข้า เรากำลังรอคุณอยู่!`,
			keyword: `Nova88, โปรแกรมคาสิโน VIP, สิทธิพิเศษ VIP, รางวัลพิเศษ VIP, Nova Club, Nova VIP Exclusive, โบนัสอัปเกรดระดับ, เงินคืนพิเศษ VIP, โบนัส VIP, สิทธิประโยชน์สำหรับสมาชิก VIP`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88.net/th/vip`,
				},
				{ rel: 'canonical', href: `https://www.nova88.net/th/vip` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/vip',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/vip',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/vip',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/vip',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/vip',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/vip',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/vip',
					hrefLang: 'bn-BD',
				},
			],
		},
		id: {
			title: `Program VIP Nova88 | Member VIP | Hadiah Ekskluisif`,
			description: `Jadilah VIP Eksklusif Nova88 Hari Ini Untuk Menikmati Keuntungan Dan Keistimewaan Dengan Sistem VIP. Main Lebih Banyak, Hadiah Lebih Banyak. Ayo, Kami Menunggu Anda!`,
			keyword: `Nova88, Program VIP Casino, Keuntungan VIP, Hadiah Eksklusif VIP, Club Nova, Eksklusif Nova VIP, Bonus Peningkatan Tier, Bonus Spesial Rebate VIP, Bonus VIP, Keuntungan Anggota VIP`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88.net/id/vip`,
				},
				{ rel: 'canonical', href: `https://www.nova88.net/id/vip` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/vip',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/vip',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/vip',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/vip',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/vip',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/vip',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/vip',
					hrefLang: 'bn-BD',
				},
			],
		},
		hi: {
			title: `Nova88 VIP प्रोग्राम | VIP सदस्य | एक्सक्लूसिव रिवॉर्ड`,
			description: `आज ही Nova88 एक्सक्लूसिव VIP बनें और अलग-अलग सदस्यता टियर के साथ लाभ और विशेषाधिकारों का आनंद लें। अधिक खेलें, अधिक पुरस्कार। जल्दी करें, हम आपका इंतजार कर रहे हैं!`,
			keyword: `Nova88, कैसीनो VIP प्रोग्राम, VIP विशेषाधिकार, VIP एक्सक्लूसिव रिवार्ड, नोवा क्लब, नोवा VIP एक्सक्लूसिव, टियर अपग्रेड बोनस, VIP स्पेशल रिबेट, VIP बोनस, VIP सदस्य लाभ`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88.net/hi/vip`,
				},
				{ rel: 'canonical', href: `https://www.nova88.net/hi/vip` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/vip',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/vip',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/vip',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/vip',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/vip',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/vip',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/vip',
					hrefLang: 'bn-BD',
				},
			],
		},
		cs: {
			title: `Nova88 贵宾计划 | VIP 会员 | 独家奖励`,
			description: `立即成为Nova88 专属VIP，享受不同会员等级的福利和特权。玩得越多，奖励越多。快点，我们在等你！`,
			keyword: `"Nova88、赌场 VIP 计划、VIP 特权、VIP 专享奖励、Nova 俱乐部、Nova VIP 专享、级别升级奖金、VIP 特别回扣、VIP 奖金、VIP 会员福利"`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88.net/cs/vip`,
				},
				{ rel: 'canonical', href: `https://www.nova88.net/cs/vip` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/vip',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/vip',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/vip',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/vip',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/vip',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/vip',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/vip',
					hrefLang: 'bn-BD',
				},
			],
		},
		bd: {
			title: `নোভা ৮৮ ভি আই পি প্রোগ্রাম, ভি আই পি সদস্য, আকর্ষনিয় পুরস্কার `,
			description: `ভিন্ন মেম্বারশিপ টিয়ার সহ সুবিধা এবং বিশেষাধিকারগুলি উপভোগ করতে আজই Nova88 আকর্ষনিয় ভি আই পি  হয়ে উঠুন। আরও খেলুন, আরও পুরস্কৃত করুন। তাড়াতাড়ি, আমরা আপনার জন্য অপেক্ষা করছি`,
			keyword: `নোভা৮৮, ক্যাসিনো ভিআইপি প্রোগ্রাম, ভিআইপি বিশেষাধিকার, ভিআইপি এক্সক্লুসিভ পুরস্কার, নোভা ক্লাব, নোভা ভিআইপি এক্সক্লুসিভ, টিয়ার আপগ্রেড বোনাস, ভিআইপি বিশেষ ছাড়, ভিআইপি বোনাস, ভিআইপি সদস্যের সুবিধা`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88.net/bd/vip`,
				},
				{ rel: 'canonical', href: `https://www.nova88.net/bd/vip` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/vip',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/vip',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/vip',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/vip',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/vip',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/vip',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/vip',
					hrefLang: 'bn-BD',
				},
			],
		},
		kh: {
			title: `Nova88 កម្មវិធី VIP |  សមាជិក VIP |  រង្វាន់នាំមុខគេ`,
			description: `ក្លាយជា VIP nova88 នាំមុខគេ ថ្ងៃនេះ ដើម្បីរីករាយជាមួយអត្ថប្រយោជន៍ និងសិទ្ធិពិសេស ជាមួយនឹងភាពខុសគ្នានៃកម្រិតសមាជិក លេងកាន់តែច្រើន រង្វាន់កាន់តែច្រើន ប្រញាប់ឡើង យើងកំពុងរង់ចាំអ្នក!`,
			keyword: `Nova88, កម្មវិធី VIP កាស៊ីណូ, សិទ្ធិ VIP, រង្វាន់ផ្តាច់មុខ VIP, ក្លឹប Nova, Nova VIP ផ្តាច់មុខ, ប្រាក់រង្វាន់លំដាប់កម្រិត, ការបញ្ចុះតម្លៃពិសេស VIP, ប្រាក់រង្វាន់ VIP, អត្ថប្រយោជន៍សមាជិក VIP`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88.net/kh/vip`,
				},
				{ rel: 'canonical', href: `https://www.nova88.net/kh/vip` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/vip',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/vip',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/vip',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/vip',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/vip',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/vip',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/vip',
					hrefLang: 'bn-BD',
				},
			],
		},
		vn: {
			title: `Chương trình VIP Nova88 | Thành viên VIP | Phần thưởng đặc biệt`,
			description: `Trở thành VIP ngay hôm nay để nhận những phần thưởng và ưu đãi đặc biệt dành cho từng hạng thành viên. Hạng càng cao, thưởng càng đã. Hàng trăm triệu đang chờ bạn!`,
			keyword: `Nova88, chương trình VIP Casino, Đặc quyền VIP, Câu Lạc Bộ Nova, Hoàn trả VIP đặc biệt, thưởng VIP, ưu đãi thành viên VIP`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88.net/vn/vip`,
				},
				{ rel: 'canonical', href: `https://www.nova88.net/vn/vip` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/vip',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/vip',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/vip',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/vip',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/vip',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/vip',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/vip',
					hrefLang: 'bn-BD',
				},
			],
		},
		ko: {
			title: `Nova88 VIP | VIP 회원 | 독점적 보상`,
			description: `Nova88 VIP 프로그램 - Nova88의 VIP 플레이어가 되었을 때 모든 혜택을 발견하세요. 높은 티어를 달성하면 독점적으로 제작된 혜택을 즐기실 수 있습니다.`,
			keyword: `Nova88, 카지노 VIP 프로그램, VIP 혜택, Nova 클럽, VIP 특별 리베이트, VIP 보너스, VIP 회원 혜택`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88.net/ko/vip`,
				},
				{ rel: 'canonical', href: `https://www.nova88.net/ko/vip` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/vip',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/vip',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/vip',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/vip',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/vip',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/vip',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/vip',
					hrefLang: 'bn-BD',
				},
			],
		},
		br: {
			title: `Nova88 VIP | Membro VIP | Recompensas Exclusivas`,
			description: `Programa VIP Nova88 - Descubra todos os benefícios de ser um jogador VIP na Nova88. Desfrute de benefícios exclusivamente adaptados quando você alcançar um nível mais alto.`,
			keyword: `Nova88, Programa VIP do Cassino, Privilégios VIP, Clube Nova, Reembolso Especial VIP, Bônus VIP, Benefícios para Membros VIP`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88.net/br/vip`,
				},
				{ rel: 'canonical', href: `https://www.nova88.net/br/vip` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/vip',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/vip',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/vip',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/vip',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/vip',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/vip',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/vip',
					hrefLang: 'bn-BD',
				},
			],
		},
	},
	[paths.sports.root]: {
		en: {
			title: `Nova88 | Asia Most Trusted Sportsbook | SABA Sports`,
			description: `Nova88 Offers Wide Variety of Sports Games To Keep Players Entertained. Officially Licensed and Subsidiary of SABA Sports. Don't Miss Any Betting Opportunity!`,
			keyword: `Nova88, Sports Betting, SABA Sports, Soccer Betting, Soccer, Soccer Leagues, Basketball, Tennis, Cricket, Virtual Sports, Volleyball, World Cup, EURO`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88.net/en/leaderBoard`,
				},
				{
					rel: 'canonical',
					href: `https://www.nova88.net/en/leaderBoard`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/leaderBoard',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/leaderBoard',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/leaderBoard',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/leaderBoard',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/leaderBoard',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/leaderBoard',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/leaderBoard',
					hrefLang: 'bn-BD',
				},
			],
		},
		cs: {
			title: `Nova88 欧亚体育 - 亚洲最受信任在线体育博彩`,
			description: `Nova88 欧亚体育 | 体育博彩提供您广泛的赛事，最佳赔率，多种投注类型，覆盖现场直播，以及精彩的百练赛。马上体验！`,
			keyword: `Nova88 欧亚体育，Nova88 欧亚体育 体育博彩，在线足球，在线博彩，即时比分，篮球庄家，足球赛事，在线体育，足球盘口`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88105.net/cs/leaderBoard`,
				},
				{
					rel: 'canonical',
					href: `https://www.nova88105.net/cs/leaderBoard`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/leaderBoard',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/leaderBoard',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/leaderBoard',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/leaderBoard',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/leaderBoard',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/leaderBoard',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/leaderBoard',
					hrefLang: 'bn-BD',
				},
			],
		},
		th: {
			title: `Nova88 - หน้าเว็บเดิมพันกีฬาออนไลน์ที่น่าเชื่อถือที่สุดในเอเชีย`,
			description: `Nova88 หน้าเว็บเดิมพันกีฬาที่มีการแข่งขันที่มากที่สุด อัตราต่อรองที่ดีที่สุดและแฮนดิแคปเอเชียพร้อมสตรีมสด อีกทั้งยังมีเกมส์หมายเลขพิเศษ. เล่นเลย!`,
			keyword: `Nova88 หน้าเว็บเดิมพันกีฬา, ฟุตบอลออนไลน์, การเดิมพันออนไลน์, ผลบอลสด, เจ้ามือรับแทงบาสเก็ตบอล, การแข่งขันฟุตบอล, กีฬาออนไลน์, เกมส์ฟุตบอล`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88bkk.com/th/leaderBoard`,
				},
				{
					rel: 'canonical',
					href: `https://www.nova88bkk.com/th/leaderBoard`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/leaderBoard',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/leaderBoard',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/leaderBoard',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/leaderBoard',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/leaderBoard',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/leaderBoard',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/leaderBoard',
					hrefLang: 'bn-BD',
				},
			],
		},
		id: {
			title: `Nova88 - Taruhan Bola Terpercaya - Situs Bola Resmi`,
			description: `Nova88 Sports menawarkan berbagai taruhan olahraga dengan odds dan handicap Asia terbaik, dengan live streaming dan juga permainan angka. Main Sekarang!`,
			keyword: `Nova88, Nova88 Sports, Taruhan Olahraga, Nova88 Indo, Situs Bola Terpercaya, Situs Bola Lisensi Resmi, Bola Online, Judi Bola`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88-indo805.info/id/leaderBoard`,
				},
				{
					rel: 'canonical',
					href: `https://www.nova88-indo805.info/id/leaderBoard`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/leaderBoard',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/leaderBoard',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/leaderBoard',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/leaderBoard',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/leaderBoard',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/leaderBoard',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/leaderBoard',
					hrefLang: 'bn-BD',
				},
			],
		},
		kh: {
			title: `Nova88 | Asia Most Trusted Sportsbook | SABA Sports`,
			description: `Nova88 Offers Wide Variety of Sports Games To Keep Players Entertained. Officially Licensed and Subsidiary of SABA Sports. Don't Miss Any Betting Opportunity!`,
			keyword: `Nova88, Sports Betting, SABA Sports, Soccer Betting, Soccer, Soccer Leagues, Basketball, Tennis, Cricket, Virtual Sports, Volleyball, World Cup, EURO`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88.net/kh/leaderBoard`,
				},
				{
					rel: 'canonical',
					href: `https://www.nova88.net/kh/leaderBoard`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/leaderBoard',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/leaderBoard',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/leaderBoard',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/leaderBoard',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/leaderBoard',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/leaderBoard',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/leaderBoard',
					hrefLang: 'bn-BD',
				},
			],
		},
		bd: {
			title: `নোভা ৮৮, এশিয়া সবচেয়ে বিশস্ত খেলাধুলা বই, এস এ বি এ খেলাধুলা `,
			description: `নোভা৮৮খেলোয়াড়দের বিনোদনের জন্য বিভিন্ন ধরনের স্পোর্টস গেম অফার করে। আনুষ্ঠানিকভাবে লাইসেন্সপ্রাপ্ত এবং সাবা স্পোর্টসের সহায়ক। কোনো বাজির সুযোগ মিস করবেন না`,
			keyword: `নোভা৮৮, স্পোর্টস বেটিং, সাবা স্পোর্টস, সকার বেটিং, সকার, সকার লীগ, বাস্কেটবল, টেনিস, ক্রিকেট, ভার্চুয়াল স্পোর্টস, ভলিবল, বিশ্বকাপ, ইউরো`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88.net/bd/leaderBoard`,
				},
				{
					rel: 'canonical',
					href: `https://www.nova88.net/bd/leaderBoard`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/leaderBoard',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/leaderBoard',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/leaderBoard',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/leaderBoard',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/leaderBoard',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/leaderBoard',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/leaderBoard',
					hrefLang: 'bn-BD',
				},
			],
		},
		vn: {
			title: `Nova88 | Nhà cái cá độ thể thao hàng đầu châu Á | SABA Sports`,
			description: `Nova88 mang đến đa dạng các môn thể thao với trải nghiệm giải trí và cá độ đỉnh cao. Là đối tác chính thức của SABA Sports. Đừng bỏ lỡ cơ hội thắng đậm hôm nay!`,
			keyword: `Nova88, cá độ thể thao, SABA Sports, cá độ bóng đá, bóng đá, giải bóng đá, bóng rổ, tennis, cricket, thể thao ảo, bóng chuyền, World Cup, EURO`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88.net/vn/leaderBoard`,
				},
				{
					rel: 'canonical',
					href: `https://www.nova88.net/vn/leaderBoard`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/leaderBoard',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/leaderBoard',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/leaderBoard',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/leaderBoard',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/leaderBoard',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/leaderBoard',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/leaderBoard',
					hrefLang: 'bn-BD',
				},
			],
		},
		ko: {
			title: `Nova88 | 스포츠 베팅 | SABA Sports`,
			description: `Nova88은(는) 한국의 주요 스포츠 북 중 하나입니다. Nova88은(는) 야구, 축구, 배구, 농구, 골프 등 다양한 스포츠 이벤트를 제공합니다.`,
			keyword: `Nova88 스포츠, 축구 베팅, 야구 북메이커, 축구 게임, 스포츠 베팅, Nova88 스포츠북, SABA 스포츠`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88.net/ko/leaderBoard`,
				},
				{
					rel: 'canonical',
					href: `https://www.nova88.net/ko/leaderBoard`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/leaderBoard',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/leaderBoard',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/leaderBoard',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/leaderBoard',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/leaderBoard',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/leaderBoard',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/leaderBoard',
					hrefLang: 'bn-BD',
				},
			],
		},
		br: {
			title: `Nova88 | Apostas Esportivas | Esportes SABA`,
			description: `Nova88 é um dos principais sportsbooks no Brasil. Nova88 oferece uma ampla gama de eventos esportivos como futebol, basquete, tênis, golfe e muito mais.`,
			keyword: `Nova88 Esportes, Futebol, Basquete, Beisebol, Casa de Apostas no Brasil, Jogo de Futebol, Apostas Esportivas, Livros Esportivos da Nova88, Esportes da SABA`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88.net/br/leaderBoard`,
				},
				{
					rel: 'canonical',
					href: `https://www.nova88.net/br/leaderBoard`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/leaderBoard',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/leaderBoard',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/leaderBoard',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/leaderBoard',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/leaderBoard',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/leaderBoard',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/leaderBoard',
					hrefLang: 'bn-BD',
				},
			],
		},
	},
	[paths.eSport.root]: {
		en: {
			title: `Nova88 |  Esports Betting | Esports Casino`,
			description: `Bet At Nova88, The Most Popular Esports Games such as Dota2, LOL, CS:GO and King of Glory. Get Excited About The Latest Esports Betting Odds. Start now!`,
			keyword: `Nova88 Esports, Nova88 Gaming, Esports Games, Esports Event, Esports Tournaments, Esports Betting, Dota2, LOL, King of Glory, Counter-Strike, CS GO`,
			links: [
				{
					rel: 'canonical',
					href: `https://www.nova88.net/en/eSport`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/eSport',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/eSport',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/eSport',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/eSport',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/eSport',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/eSport',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/eSport',
					hrefLang: 'bn-BD',
				},
			],
		},
		th: {
			title: `Nova88 | เดิมพันอีสปอร์ต| อีสปอร์ตคาสิโน`,
			description: `เดิมพันที่ Nova88 เกม Esports ที่ได้รับความนิยมสูงสุด เช่น Dota2, LOL, CS:GO และ King of Glory ตื่นตาตื่นใจกับอัตราเดิมพันอีสปอร์ตล่าสุด เริ่มเลย!`,
			keyword: `Nova88 อีสปอร์ต, Nova88 Gaming, เกมส์ อีสปอร์ต, กิจกรรม อีสปอร์ต, อีสปอร์ต ทัวร์นาเมนต์, วางเดิมพัน อีสปอร์ต, Dota2, LOL, King of Glory, Counter-Strike, CS GO`,
			links: [
				{
					rel: 'canonical',
					href: `https://www.nova88.net/th/eSport`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/th/eSport',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/eSport',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/eSport',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/eSport',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/eSport',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/eSport',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/eSport',
					hrefLang: 'bn-BD',
				},
			],
		},
		id: {
			title: `Nova88 | Taruhan Esports | Casino Esports`,
			description: `Taruhan Di Nova88, Game Esports Terpopuler Seperti Dota2, LOL, CS:GO dan King of Glory. Bersemangatlah Untuk Bertaruh Esports Terbaru. Mulai sekarang!`,
			keyword: `Nova88 Esports , Nova88 Gaming, Game Esports, Event Esports, Turnamen Esports, Taruhan Esports, Dota2, LOL, King of Glory, Counter-Strike, CS GO`,
			links: [
				{
					rel: 'canonical',
					href: `https://www.nova88.net/id/eSport`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/id/eSport',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/eSport',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/eSport',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/eSport',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/eSport',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/eSport',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/eSport',
					hrefLang: 'bn-BD',
				},
			],
		},
		hi: {
			title: `Nova88 | ईस्पोर्ट्स बेटिंग | एस्पोर्ट्स कैसीनो`,
			description: `Nova88 पर बेट लगाएं, Dota2, LOL, CS:GO और किंग ऑफ ग्लोरी जैसे सबसे लोकप्रिय Esports गेम्स। नवीनतम ईस्पोर्ट्स बेटिंग ऑड्स के बारे में उत्साहित हों। अभी शुरू करो!`,
			keyword: `Nova88 ईस्पोर्ट्स, Nova88 गेमिंग, ईस्पोर्ट्स गेम्स, ईस्पोर्ट्स इवेंट, ईस्पोर्ट्स टूर्नामेंट, ईस्पोर्ट्स बेटिंग, Dota2, LOL, King of Glory, काउंटर-स्ट्राइक, CS GO`,
			links: [
				{
					rel: 'canonical',
					href: `https://www.nova88.net/hi/eSport`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/hi/eSport',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/eSport',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/eSport',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/eSport',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/eSport',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/eSport',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/eSport',
					hrefLang: 'bn-BD',
				},
			],
		},
		cs: {
			title: `Nova88 | 电子竞技博彩 | 电子竞技艺游`,
			description: `Nova88提供各类热门电子竞技比赛如刀塔2，反恐精英，英雄联盟，王者荣耀等！赶紧来为你喜爱的电竞队伍加油打气！`,
			keyword: `Nova88电竞、Nova88游戏、电竞游戏、电竞赛事、电竞锦标赛、电竞博彩、刀塔2、英雄联盟、王者荣耀、反恐精英、CS GO`,
			links: [
				{
					rel: 'canonical',
					href: `https://www.nova88.net/cs/eSport`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/cs/eSport',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/eSport',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/eSport',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/eSport',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/eSport',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/eSport',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/eSport',
					hrefLang: 'bn-BD',
				},
			],
		},
		bd: {
			title: `Nova88, ই-ক্রীড়া বেটিং, ই-ক্রীড়া ক্যাসিনো `,
			description: `নোভা৮৮-এ বিট করুন, ডাটা ২, লল , সিস :জি ও এবং -এর মতো সর্বাধিক জনপ্রিয় এস্পোর্টস গেম। সর্বশেষ এস্পোর্টস বেটিং অডস সম্পর্কে উত্তেজিত হন। এখনই শুরু করুন !`,
			keyword: `Nova88 ই-স্পোর্টস, Nova88 গেমিং, ই-স্পোর্টস গেমিং, ই-স্পোর্টস টুর্নামেন্ট, ই-স্পোর্টস বাজি, Dota2, LOL, গৌরবের রাজা, কাউন্টার স্ট্রাইক, CSGO`,
			links: [
				{
					rel: 'canonical',
					href: `https://www.nova88.net/bd/eSport`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/bd/eSport',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/eSport',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/eSport',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/eSport',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/eSport',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/eSport',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/eSport',
					hrefLang: 'bn-BD',
				},
			],
		},
		kh: {
			title: `Nova88 |  ការភ្នាល់ Esports |  កាស៊ីណូ Esports`,
			description: `ភ្នាល់នៅ Nova88 ដែលជាហ្គេម Esports ដែលពេញនិយមបំផុតដូចជា Dota2, LOL, CS:GO និង King of Glory ទទួលបានអារម្មណ៍រំភើបអំពីហាងឆេងភ្នាល់ Esports ចុងក្រោយបំផុត ចាប់ផ្តើមឥឡូវនេះ!`,
			keyword: `Nova88 Esports, Nova88 Gaming, ហ្គេម Esports , ព្រឹត្តិការណ៍ Esports , ការប្រកួត Esports, ការភ្នាល់ Esports, Dota2, LOL, King of Glory, Counter-Strike, CS GO`,
			links: [
				{
					rel: 'canonical',
					href: `https://www.nova88.net/kh/eSport`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/kh/eSport',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/eSport',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/eSport',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/eSport',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/eSport',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/eSport',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/eSport',
					hrefLang: 'bn-BD',
				},
			],
		},
		vn: {
			title: `Nova88 | Cá độ thể thao điện tử | Casino thể thao điện tử`,
			description: `Đặt cược tại Nova88 với các game thể thao điện tử phổ biến nhất như Dota2, LMHT, CS:GO và Vương giả vinh diệu. Tỷ lệ cá cược tốt nhất và mới nhất thị trường. Bắt đầu ngay hôm nay!`,
			keyword: `Thể thao điện tử Nova88, chơi game Nova88, game thể thao điện tử, sự kiện thể thao điện tử, giải đấu thể thao điện tử, cá độ thể thao điện tử, Dota2, LMHT, Vương giả vinh diệu, Counter-Strike, CS GO`,
			links: [
				{
					rel: 'canonical',
					href: `https://www.nova88.net/vn/eSport`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/eSport',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/eSport',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/eSport',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/eSport',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/eSport',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/eSport',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/eSport',
					hrefLang: 'bn-BD',
				},
			],
		},
		ko: {
			title: `Nova88 | 전자 스포츠 베팅 | 전자 스포츠 카지노`,
			description: `Nova88은(는) 전 세계의 e스포츠에 베팅할 수 있도록 하며, 한국 플레이어에게 새로운 게임 경험을 제공합니다.`,
			keyword: `Nova88 전자 스포츠, Nova88 게임, 전자 스포츠 게임, 전자 스포츠 이벤트, 전자 스포츠 토너먼트, 전자 스포츠 베팅`,
			links: [
				{
					rel: 'canonical',
					href: `https://www.nova88.net/ko/eSport`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/eSport',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/eSport',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/eSport',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/eSport',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/eSport',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/eSport',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/eSport',
					hrefLang: 'bn-BD',
				},
			],
		},
		br: {
			title: `Nova88 | Apostas em Esports | Cassino de Esports`,
			description: `Nova88 permite que você aposte em e-sports de todo o mundo, oferecendo aos brasileiros uma nova experiência de jogo.`,
			keyword: `Nova88 Esports, Jogos da Nova88, Jogos de Esports, Eventos de Esports, Torneios de Esports, Apostas em Esports`,
			links: [
				{
					rel: 'canonical',
					href: `https://www.nova88.net/br/eSport`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/eSport',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/eSport',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/eSport',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/eSport',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/eSport',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/eSport',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/eSport',
					hrefLang: 'bn-BD',
				},
			],
		},
	},
	[paths.liveCasino.root]: {
		en: {
			title: `Nova88 | Trusted Live Casino | Play & Win!`,
			description: `Nova88 Has Been In The Industry For Over 20 Years. There Are Various of Live Casino Games Offered, Such As Baccarat, Roulette, Blackjack, Poker And Many More.`,
			keyword: `Online Casino, Casino Games, Live Gambling, Baccarat, Roulette, Blackjack, Poker, Slots, Game Shows, Sic Bo, Dragon Tiger, Pragmatic Play, Microgaming, Evolution Gaming, Playtech, Dream Gaming, AE Sexy, SA Gaming, Allbet, Asia Gaming, WM Casino, eBet`,
			links: [
				{
					rel: 'canonical',
					href: `https://www.nova88.net/en/liveCasino`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/liveCasino',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/liveCasino',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/liveCasino',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/liveCasino',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/liveCasino',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/liveCasino',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/liveCasino',
					hrefLang: 'bn-BD',
				},
			],
		},
		th: {
			title: `Nova88 | คาสิโนออนไลน์ | ชนะ`,
			description: `Nova88 เปิดให้บริการมาแล้วมากกว่า 20 ปี มีเกมคาสิโนสดให้เลือกมากมาย เช่น บาคาร่า รูเล็ต แบล็กแจ็ก โป๊กเกอร์ และอื่นๆ อีกมากมาย`,
			keyword: `คาสิโนออนไลน์, เกมส์คาสิโน, การพนันสด, บาคาร่า, รูเล็ต, แบล็คแจ็ค, โป๊กเกอร์, สล็อต, เกมส์โชว์, ซิกโบ, เสือมังกร, Pragmatic Play, Microgaming, Evolution Gaming, Playtech, Dream Gaming, AE Sexy, SA Gaming, Allbet, Asia Gaming, WM คาสิโน, eBet`,
			links: [
				{
					rel: 'canonical',
					href: `https://www.nova88.net/th/liveCasino`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/liveCasino',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/liveCasino',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/liveCasino',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/liveCasino',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/liveCasino',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/liveCasino',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/liveCasino',
					hrefLang: 'bn-BD',
				},
			],
		},
		id: {
			title: `Nova88 | Live Casino Terpercaya | Main & Menang!`,
			description: `Nova88 Sudah Dipercaya Lebih Dari 20 Tahun. Ada Berbagai Permainan Live Casino Yang Ditawarkan, Seperti Baccarat, Roulette, Blackjack, Poker Dan Masih Banyak Lagi.`,
			keyword: `Casino Online, Permainan Casino, Taruhan Online, Baccarat, Roulette, Blackjack, Poker, Slot, Game Shows, Sic Bo, Dragon Tiger, Pragmatic Play, Microgaming, Evolution Gaming, Playtech, Dream Gaming, AE Sexy, SA Gaming, Allbet, Asia Gaming, WM Casino, eBet`,
			links: [
				{
					rel: 'canonical',
					href: `https://www.nova88.net/id/liveCasino`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/liveCasino',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/liveCasino',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/liveCasino',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/liveCasino',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/liveCasino',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/liveCasino',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/liveCasino',
					hrefLang: 'bn-BD',
				},
			],
		},
		hi: {
			title: `Nova88 | विश्वसनीय लाइव कैसीनो | खेलो और जीतो!`,
			description: `Nova88 20 से अधिक वर्षों से उद्योग में है। कई तरह के लाइव कैसिनो गेम्स की पेशकश की जाती है, जैसे कि बैकारेट, रूलेट, ब्लैकजैक, पोकर और भी बहुत कुछ।`,
			keyword: `ऑनलाइन कसीनो, कैसिनो गेम्स, लाइव जुआ, बैकारेट, रूले, ब्लैकजैक, पोकर, स्लॉट्स, गेम शो, सिक बो, ड्रैगन टाइगर, व्यावहारिक खेल, माइक्रोगेमिंग, एवोल्यूशन गेमिंग, प्लेटेक, ड्रीम गेमिंग, एई सेक्सी, एसए गेमिंग, ऑलबेट, एशिया गेमिंग, WM कैसीनो, eBet`,
			links: [
				{
					rel: 'canonical',
					href: `https://www.nova88.net/hi/liveCasino`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/liveCasino',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/liveCasino',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/liveCasino',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/liveCasino',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/liveCasino',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/liveCasino',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/liveCasino',
					hrefLang: 'bn-BD',
				},
			],
		},
		cs: {
			title: `Nova88 | 信誉保证的真人娱乐场 | 一起玩，一起赢！`,
			description: `Nova88 在业内已有 20 多年的历程。提供各种真人娱乐游戏，例如百家乐、轮盘、二十一点、扑克等等。`,
			keyword: `线上游戏、真人游戏、百家乐、轮盘、二十一点、扑克、老虎机、游戏节目、骰宝、龙虎、Pragmatic Play、Microgaming、Evolution Gaming、Playtech、Dream Gaming、AE Sexy、SA Gaming、Allbet、Asia Gaming, WM Casino、eBet`,
			links: [
				{
					rel: 'canonical',
					href: `https://www.nova88.net/cs/liveCasino`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/liveCasino',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/liveCasino',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/liveCasino',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/liveCasino',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/liveCasino',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/liveCasino',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/liveCasino',
					hrefLang: 'bn-BD',
				},
			],
		},
		bd: {
			title: `নোভা ৮৮, সরাসরি বিশস্ত ক্যাসিনো , খেলা করা এবং জিতে নাও `,
			description: `নোভা৮৮ ২০ বছরেরও বেশি সময় ধরে শিল্পে রয়েছে। এখানে বিভিন্ন সরাসরি ক্যাসিনো গেম অফার করা হয়, যেমন ব্যাকার্যাট, রুলেট, ব্ল্যাকজ্যাক, পোকার এবং আরও অনেক কিছু`,
			keyword: `অনলাইন ক্যাসিনো, ক্যাসিনো গেমস, লাইভ জুয়া, ব্যাকার্যাট, রুলেট, ব্ল্যাকজ্যাক, পোকার, স্লটস, গেম শো, সিক বো, ড্রাগন টাইগার, প্রাগম্যাটিক প্লে, মাইক্রোগেমিং, ইভোলিউশন গেমিং, প্লেটেক, ড্রিম গেমিং, এই সেক্সি, এসএ গেমিং, অলবেট, এশিয়া গেমিং, ডব্লিউ এম ক্যাসিনো, ই -বিট `,
			links: [
				{
					rel: 'canonical',
					href: `https://www.nova88.net/bd/liveCasino`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/liveCasino',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/liveCasino',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/liveCasino',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/liveCasino',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/liveCasino',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/liveCasino',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/liveCasino',
					hrefLang: 'bn-BD',
				},
			],
		},
		kh: {
			title: `Nova88 |  កាស៊ីណូផ្សាយផ្ទាល់គួរឱ្យទុកចិត្ត |  លេង & ឈ្នះ!`,
			description: `Nova88 បាននៅក្នុងឧស្សាហកម្មនេះអស់រយៈពេលជាង 20 ឆ្នាំមកហើយ មានហ្គេមកាស៊ីណូផ្សាយផ្ទាល់ជាច្រើនដែលផ្តល់ជូន ដូចជា  Baccarat, Roulette, Blackjack, Poker និងច្រើនទៀត`,
			keyword: `កាស៊ីណូអនឡាញ ហ្គេមកាស៊ីណូ ល្បែងភ្នាល់ផ្សាយផ្ទាល់ , Baccarat, Roulette, Blackjack, Poker, Slots, Game Shows, Sic Bo, Dragon Tiger, Pragmatic Play, Microgaming, Evolution Gaming, Playtech, Dream Gaming, AE Sexy, SA Gaming, Allbet, Asia Gaming, WM Casino, eBet`,
			links: [
				{
					rel: 'canonical',
					href: `https://www.nova88.net/kh/liveCasino`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/liveCasino',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/liveCasino',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/liveCasino',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/liveCasino',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/liveCasino',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/liveCasino',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/liveCasino',
					hrefLang: 'bn-BD',
				},
			],
		},
		vn: {
			title: `Nova88 | Live Casino uy tín | Chơi ngay thắng đậm`,
			description: `Nova88 đã ở trên thị trường hơn 20 năm cung cấp các trò Live Casino hấp dẫn như Baccarat, Roulette, Blackjack, Poker và hơn thế nữa.`,
			keyword: `Casino trực tuyến, game casino, Baccarat, Roulette, xì dách, Poker, quay hũ, tài xỉu, rồng hổ, Pragmatic Play, Microgaming, Evolution Gaming, Playtech, Dream Gaming, SA Gaming, Allbet, Asia Gaming, WM Casino`,
			links: [
				{
					rel: 'canonical',
					href: `https://www.nova88.net/vn/liveCasino`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/liveCasino',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/liveCasino',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/liveCasino',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/liveCasino',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/liveCasino',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/liveCasino',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/liveCasino',
					hrefLang: 'bn-BD',
				},
			],
		},
		ko: {
			title: `Nova88 | 한국 최고의 온라인 카지노`,
			description: `Nova88 라이브 카지노에서 라이브 딜러와 함께 카지노 게임을 즐기세요. 바카라, 룰렛, 블랙잭, 포커 등 인기 있는 카지노 게임을 모두 즐길 수 있습니다.`,
			keyword: `Nova88, 온라인 카지노, 베팅 카지노, 온라인 바카라 게임, 온라인 베팅, 룰렛 잭팟, 라이브 카지노`,
			links: [
				{
					rel: 'canonical',
					href: `https://www.nova88.net/ko/liveCasino`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/liveCasino',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/liveCasino',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/liveCasino',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/liveCasino',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/liveCasino',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/liveCasino',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/liveCasino',
					hrefLang: 'bn-BD',
				},
			],
		},
		br: {
			title: `Nova88 | Trusted Live Casino | Play & Win!`,
			description: `Nova88 Has Been In The Industry For Over 20 Years. There Are Various of Live Casino Games Offered, Such As Baccarat, Roulette, Blackjack, Poker And Many More.`,
			keyword: `Online Casino, Casino Games, Live Gambling, Baccarat, Roulette, Blackjack, Poker, Slots, Game Shows, Sic Bo, Dragon Tiger, Pragmatic Play, Microgaming, Evolution Gaming, Playtech, Dream Gaming, AE Sexy, SA Gaming, Allbet, Asia Gaming, WM Casino, eBet`,
			links: [
				{
					rel: 'canonical',
					href: `https://www.nova88.net/en/liveCasino`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/liveCasino',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/liveCasino',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/liveCasino',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/liveCasino',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/liveCasino',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/liveCasino',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/liveCasino',
					hrefLang: 'bn-BD',
				},
			],
		},
	},
	[paths.slots.root]: {
		en: {
			title: `Nova88 | Best Online Slots | Asia's Most Trusted Platform`,
			description: `Explore More Slot Games in Nova88. Login Everyday And Enjoy The Games. Get Appreciation Token and Weekly Free Spins From Us. Don't Miss Out, Claim Now!`,
			keyword: `Slots, Online Slots, Casino Slot Games, Slot Machine, Free Spins, Habanero, Jili, Mega888, Pragmatic Play, NetEnt, Game Play, YGGdrasil, Joker, AdvantPlay, Spade Gaming, Red Tiger, CQ9, Playtech`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88.net/en/slots`,
				},
				{ rel: 'canonical', href: `https://www.nova88.net/en/slots` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/slots',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/slots',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/slots',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/slots',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/slots',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/slots',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/slots',
					hrefLang: 'bn-BD',
				},
			],
		},
		cs: {
			title: `Nova88 欧亚体育 - 马来西亚在线电子老虎机`,
			description: `Nova88 欧亚体育马来西亚最热门和最新的在线赌场老虎机游戏。 旋转并赢得奖金，积宝大奖老虎机，918kiss，mega888，Pragmatic Play。`,
			keyword: `Nova88 欧亚体育，Nova88 欧亚体育老虎机 , 918kiss, mega88, Pragmatic Play, 线上老虎机, 在线老虎机, 在线电子游戏, 积宝奖金老虎机, 现金老虎机`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88105.net/cs/slots`,
				},
				{
					rel: 'canonical',
					href: `https://www.nova88105.net/cs/slots`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/slots',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/slots',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/slots',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/slots',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/slots',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/slots',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/slots',
					hrefLang: 'bn-BD',
				},
			],
		},
		th: {
			title: `Nova88 - การเดิมพันเกมส์สล็อตออนไลน์ในไทย`,
			description: `Nova88  เว็บสล็อตคาสิโนออนไลน์ที่ใหม่และมาแรงที่สุดในไทย เล่นจริง จ่ายจริง!  Jackpot สล็อต, 918kiss, mega888, pragmatic  เล่นเลย.`,
			keyword: `Nova88, สล็อต Nova88, 918kiss, mega88, pragmatic slot, สล็อตออนไลน์, สล็อตคาสิโนออนไลน์, คาสิโนสล็อต, สล็อตที่ดีที่สุด, จ่ายจริง`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nnova88bkk.com/th/slots`,
				},
				{
					rel: 'canonical',
					href: `https://www.nova88bkk.com/th/slots`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/slots',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/slots',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/slots',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/slots',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/slots',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/slots',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/slots',
					hrefLang: 'bn-BD',
				},
			],
		},
		id: {
			title: `Nova88 - Situs Judi Slot Mesin Online Indonesia`,
			description: `Nova88 adalah Situs Slot Online, Judi slot jackpot terpopuler dan terbaru di Indonesia. Putar dan menangkan uang asli di Slot Mesin Pragmatic, Joker123`,
			keyword: `Nova88, Nova88 Slot Mesin Online, Slot online, Judi Mesin Slot, Slot Kasino, Slot pragmatic, slot joker123.`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88-indo805.info/id/slots`,
				},
				{
					rel: 'canonical',
					href: `https://www.nova88-indo805.info/id/slots`,
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/slots',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/slots',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/slots',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/slots',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/slots',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/slots',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/slots',
					hrefLang: 'bn-BD',
				},
			],
		},
		kh: {
			title: `Nova88 | Best Online Slots | Asia's Most Trusted Platform`,
			description: `Explore More Slot Games in Nova88. Login Everyday And Enjoy The Games. Get Appreciation Token and Weekly Free Spins From Us. Don't Miss Out, Claim Now!`,
			keyword: `Slots, Online Slots, Casino Slot Games, Slot Machine, Free Spins, Habanero, Jili, Mega888, Pragmatic Play, NetEnt, Game Play, YGGdrasil, Joker, AdvantPlay, Spade Gaming, Red Tiger, CQ9, Astro Tech, Playtech`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88.net/kh/slots`,
				},
				{ rel: 'canonical', href: `https://www.nova88.net/kh/slots` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/slots',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/slots',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/slots',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/slots',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/slots',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/slots',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/slots',
					hrefLang: 'bn-BD',
				},
			],
		},
		bd: {
			title: `নোভা ৮৮, অনলাইনে সবচেয়ে ভালো স্লট, এশিয়ার সবচেয়ে বিশস্ত প্লাটফর্ম `,
			description: `নোভা৮৮-এ আরও স্লট গেম খুঁজ  করুন। প্রতিদিন লগইন করুন এবং গেমগুলি উপভোগ করুন। আমাদের কাছ থেকে প্রশংসা টোকেন এবং সাপ্তাহিক ফ্রি স্পিন পান। মিস করবেন না, এখনই দাবি করুন!`,
			keyword: `স্লট, অনলাইন স্লট, ক্যাসিনো স্লট গেম, স্লট মেশিন, ফ্রি স্পিন, হাবানেরো, জিলি, মেগা৮৮৮, প্রাগম্যাটিক প্লে, নেটএন্ট, গেম প্লে, ওয়াইজিজিড্রাসিল, জোকার, অ্যাডভান্টপ্লে, স্পেড গেমিং, রেড টাইগার, সিকিউ৯, অ্যাস্ট্রো টেক, প্লেটেক`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88.net/bd/slots`,
				},
				{ rel: 'canonical', href: `https://www.nova88.net/bd/slots` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/slots',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/slots',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/slots',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/slots',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/slots',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/slots',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/slots',
					hrefLang: 'bn-BD',
				},
			],
		},
		hi: {
			title: `Nova88 | सर्वश्रेष्ठ ऑनलाइन स्लॉट | एशिया का सबसे भरोसेमंद प्लेटफॉर्म`,
			description: `Nova88 में और स्लॉट गेम्स एक्सप्लोर करें। प्रतिदिन लॉग इन करें और खेलों का आनंद लें। हम से एप्रिसिएशन टोकन और साप्ताहिक फ्री स्पिन प्राप्त करें। चूकें नहीं, अभी दावा करें!`,
			keyword: `स्लॉट्स, ऑनलाइन स्लॉट्स, कैसिनो स्लॉट गेम्स, स्लॉट मशीन, फ्री स्पिन्स, हैबनेरो, जिली, मेगा888, व्यावहारिक प्ले, नेटएंट, गेम प्ले, YGGdrasil, जोकर, एडवेंटप्ले, स्पेड गेमिंग, रेड टाइगर, CQ9, एस्ट्रो टेक, प्लेटेक`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88.net/hi/slots`,
				},
				{ rel: 'canonical', href: `https://www.nova88.net/hi/slots` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/slots',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/slots',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/slots',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/slots',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/slots',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/slots',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/slots',
					hrefLang: 'bn-BD',
				},
			],
		},
		vn: {
			title: `Nova88 | Quay hũ trực tuyến tốt nhất | Nền tảng giải trí hàng đầu châu Á`,
			description: `Khám phá vô vàn các game quay hũ tại Nova88. Đăng nhập mỗi ngày, chơi liền tay. Nhận quà đặc biệt và vòng quay miễn phí mỗi tuần. Đừng bỏ lỡ!`,
			keyword: `Quay hũ, quay hũ trực tuyến, game quay hũ casino, máy quay hũ, vòng quay hũ miễn phí, Habanero, Jili, Mega888, Pragmatic Play, Game Play, YGGdrasil, Joker, Spade Gaming, Red Tiger, CQ9, Astro Tech, Playtech`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88.net/vn/slots`,
				},
				{ rel: 'canonical', href: `https://www.nova88.net/vn/slots` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/slots',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/slots',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/slots',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/slots',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/slots',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/slots',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/slots',
					hrefLang: 'bn-BD',
				},
			],
		},
		ko: {
			title: `Nova88 | 최고의 온라인 슬롯 | 잭팟 당첨`,
			description: `다양한 온라인 슬롯 게임에서 두 배의 즐거움을 느껴보세요. 무료 스핀 보너스를 즐기고 잭팟을 터뜨려 실제 돈을 따세요!`,
			keyword: `슬롯, 온라인 슬롯, 카지노 슬롯 게임, 슬롯 머신, 무료 스핀, 잭팟, 슬롯 제공업체`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88.net/ko/slots`,
				},
				{ rel: 'canonical', href: `https://www.nova88.net/ko/slots` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/slots',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/slots',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/slots',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/slots',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/slots',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/slots',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/slots',
					hrefLang: 'bn-BD',
				},
			],
		},
		br: {
			title: `Nova88 | Melhores Slots Online | Ganhe Jackpots`,
			description: `Dobre a diversão e ganhe dinheiro real em uma variedade de jogos de caça-níqueis online. Aproveite bônus de giros grátis e ganhe o jackpot!`,
			keyword: `Caça-níqueis, Caça-níqueis Online, Jogos de Caça-níqueis de Cassino, Máquina de Caça-níqueis, Giros Grátis, Jackpot, Provedor de Caça-níqueis`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88.net/br/slots`,
				},
				{ rel: 'canonical', href: `https://www.nova88.net/br/slots` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/slots',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/slots',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/slots',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/slots',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/slots',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/slots',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/slots',
					hrefLang: 'bn-BD',
				},
			],
		},
	},
	[paths.oneGame.root]: {
		en: {
			title: `Nova88 | Online Poker Games | Best Poker Sites`,
			description: `Enjoy The Thrilling Online Poker Games At Nova88. Join Now To Play With Real Money And Stand A Chance To Double Your Wins!`,
			keyword: `Online Poker, Poker Game, Poker Betting, Texas Holdem Poker, 3 Card Poker`,
			links: [
				{
					rel: 'alternate',
					media: 'only screen and (max-width: 640px)',
					href: `https://m.nova88.net/en/oneGame`,
				},
				{ rel: 'canonical', href: `https://www.nova88.net/en/oneGame` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/oneGame',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/oneGame',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/oneGame',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/oneGame',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/oneGame',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/oneGame',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/oneGame',
					hrefLang: 'bn-BD',
				},
			],
		},
	},
	[paths.game.root]: {
		en: {
			title: `Nova88 | Casino Games | Virtual Games`,
			description: `Play The Simple, Fun And Easy Games At Nova88. We Strives To Provide Good Quality Entertainment Games For Our Players. Access To Your Favourite Games Now!`,
			keyword: `Online Casino Games, King Maker, Vgaming, Virtual Games, Plinko, Minesweeper, Heist, Virtual Horse Racing`,
			links: [
				{ rel: 'canonical', href: `https://www.nova88.net/en/game` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/game',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/game',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/game',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/game',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/game',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/game',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/game',
					hrefLang: 'bn-BD',
				},
			],
		},
		th: {
			title: `Nova88 | คาสิโน | เกมเสมือนจริง`,
			description: `เล่นเกมที่ง่าย และสนุก ที่ Nova88 เรามุ่งมั่นที่จะจัดหาเกม ความบันเทิงคุณภาพดีสำหรับผู้เล่นของเรา เข้าถึงเกมโปรดของคุณตอนนี้!`,
			keyword: `เกมคาสิโนออนไลน์, King Maker, Vgaming, เกมเสมือนจริง, Plinko, Minesweeper, Heist, Virtual Horse Racing`,
			links: [
				{ rel: 'canonical', href: `https://www.nova88.net/th/game` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/game',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/game',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/game',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/game',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/game',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/game',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/game',
					hrefLang: 'bn-BD',
				},
			],
		},
		id: {
			title: `Nova88 | Casino Online | Game Virtual`,
			description: `Slot Gampang menang dan gampang maxwin Di Nova88. Kami Berusaha Memberikan Permainan Berkualitas Untuk Para Semua Member Kami. Loginke Game Favorit Anda Sekarang!`,
			keyword: `Permainan Casino Online, King Maker, Vgaming, Game Virtual, Plinko, Minesweeper, Heist, Pacuan Kuda Virtual, Bandarq, Domino Online`,
			links: [
				{ rel: 'canonical', href: `https://www.nova88.net/id/game` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/game',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/game',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/game',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/game',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/game',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/game',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/game',
					hrefLang: 'bn-BD',
				},
			],
		},
		hi: {
			title: `Nova88 | कैसीनो गेम्स | वर्चुअल गेम्स`,
			description: `Nova88 पर सरल, मजेदार और आसान गेम्स खेलें। हम अपने खिलाड़ियों के लिए अच्छी गुणवत्ता वाले मनोरंजन गेम प्रदान करने का प्रयास करते हैं। अपने पसंदीदा खेलों में अभी प्रवेश करें!`,
			keyword: `ऑनलाइन कैसिनो गेम्स, किंग मेकर, वीगेमिंग, वर्चुअल गेम्स, प्लिंको, माइनस्वीपर, डकैती, वर्चुअल हॉर्स रेसिंग`,
			links: [
				{ rel: 'canonical', href: `https://www.nova88.net/hi/game` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/game',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/game',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/game',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/game',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/game',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/game',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/game',
					hrefLang: 'bn-BD',
				},
			],
		},
		cs: {
			title: `Nova88 | 真人娱乐游戏 | 虚拟游戏`,
			description: `Nova88竭诚为玩家提供轻松、有趣和简单的优质娱乐游戏。立即体验您最喜爱的游戏！`,
			keyword: `在线赌场游戏、King Maker、Vgaming、虚拟游戏、Plinko、Minesweeper, Heist、虚拟赛马`,
			links: [
				{ rel: 'canonical', href: `https://www.nova88.net/cs/game` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/game',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/game',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/game',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/game',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/game',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/game',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/game',
					hrefLang: 'bn-BD',
				},
			],
		},
		bd: {
			title: `নোভা ৮৮, ক্যাসিনো গেমস, ভার্চুয়াল গেমস `,
			description: `নোভা৮৮এ সহজ, মজার এবং সহজ গেম খেলুন। আমরা আমাদের খেলোয়াড়দের জন্য ভাল মানের বিনোদন গেম সরবরাহ করার চেষ্টা করি। এখন আপনার প্রিয় গেম প্রবেশ করুন `,
			keyword: `অনলাইন ক্যাসিনো গেমস, কিং মেকার, ভিগেমিং, ভার্চুয়াল গেমস, প্লিঙ্কো, মাইনসুইপার, হেইস্ট, ভার্চুয়াল হর্স রেসিং`,
			links: [
				{ rel: 'canonical', href: `https://www.nova88.net/bd/game` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/game',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/game',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/game',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/game',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/game',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/game',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/game',
					hrefLang: 'bn-BD',
				},
			],
		},
		kh: {
			title: `Nova88 |  ហ្គេមកាស៊ីណូ | Virtual ហ្គេម`,
			description: `លេងហ្គេមដ៏សាមញ្ញ សប្បាយ និងងាយស្រួលនៅ Nova88។  យើងខិតខំផ្តល់នូវហ្គេមកម្សាន្តដែលមានគុណភាពល្អសម្រាប់អ្នកលេងរបស់យើង។  ចូលទៅកាន់ហ្គេមដែលអ្នកចូលចិត្តឥឡូវនេះ!`,
			keyword: `ហ្គេមកាស៊ីណូអនឡាញ, King Maker, Vgaming, Virtual Games, Plinko, Minesweeper, Heist, Virtual Horse Racing`,
			links: [
				{ rel: 'canonical', href: `https://www.nova88.net/kh/game` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/game',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/game',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/game',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/game',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/game',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/game',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/game',
					hrefLang: 'bn-BD',
				},
			],
		},
		vn: {
			title: `Nova88 | Trò chơi Casino | Trò chơi ảo`,
			description: `Dễ chơi, dễ thắng, chơi vui cực đã tại Trò chơi của Nova88. Game giải trí chất lượng cao dành riêng cho bạn, tham gia ngay với Nova88.`,
			keyword: `Game casino trực tuyến, King Maker, game ảo, trò chơi ảo, Plinko, Minesweeper, Heist, đua ngựa ảo, game casino trực tuyến malaysia, online casino games malaysia`,
			links: [
				{ rel: 'canonical', href: `https://www.nova88.net/vn/game` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/game',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/game',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/game',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/game',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/game',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/game',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/game',
					hrefLang: 'bn-BD',
				},
			],
		},
		ko: {
			title: `Nova88 | 카지노 게임 | 가상 게임`,
			description: `Nova88에서는 다양한 고품질 엔터테인먼트 게임을 즐길 수 있습니다. 지금 바로 여러분이 좋아하는 게임을 플레이하세요!`,
			keyword: `온라인 카지노 게임, 가상 게임, 한국 온라인 카지노 게임, 한국 카지노 게임`,
			links: [
				{ rel: 'canonical', href: `https://www.nova88.net/ko/game` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/game',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/game',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/game',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/game',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/game',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/game',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/game',
					hrefLang: 'bn-BD',
				},
			],
		},
		br: {
			title: `Nova88 | Jogos de Cassino | Jogos Virtuais`,
			description: `Há muitos jogos de entretenimento de alta qualidade disponíveis na Nova88. Jogue seus jogos favoritos agora!`,
			keyword: `Jogos de Cassino Online, Jogos Virtuais, Jogos de Cassino Online no Brasil, Jogos de Cassino Brasil`,
			links: [
				{ rel: 'canonical', href: `https://www.nova88.net/br/game` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/game',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/game',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/game',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/game',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/game',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/game',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/game',
					hrefLang: 'bn-BD',
				},
			],
		},
	},
	[paths.fishing.root]: {
		en: {
			title: `Nova88 | Fishing Online | Best Fishing Games`,
			description: `Fishing Online At Nova88, Immerse Yourself Into The Deep of Sea. Select Some Juicy Bait, Shoot Down Various Sea Creatures To Collect Points And Rewards.`,
			keyword: `Fishing Game, Fishing Online, Casino Fishing Game, Fishing Game Real Money, Arcade Fishing Game, Fishing War, Fish Haiba, Happy Fish 5, Monster Awaken, Fishing World, Paradise`,
			links: [
				{ rel: 'canonical', href: `https://www.nova88.net/en/fishing` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/fishing',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/fishing',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/fishing',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/fishing',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/fishing',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/fishing',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/fishing',
					hrefLang: 'bn-BD',
				},
			],
		},
		th: {
			title: `Nova88 | เกมส์ยิงปลาออนไลน์ | เกมส์ยิงปลาออนไลน์ที่ดีที่สุด`,
			description: `เกมส์ยิงปลาออนไลน์ที่ Nova88 ดำดิ่งสู่ท้องทะเลลึก เลือกเหยื่อที่ฉ่ำน้ำ ยิงสัตว์ทะเลต่างๆ เพื่อสะสมคะแนนและรางวัล`,
			keyword: `เกมยิงปลา, เกมส์ยิงปลาออนไลน์, เกมยิงปลาคาสิโน, เกมยิงปลาได้เงินจริง, เกมยิงปลาอาร์เคด, สงครามตกปลา, ปลาไฮบะ, Happy Fish 5, Monster Awaken, Fishing World, Paradise`,
			links: [
				{ rel: 'canonical', href: `https://www.nova88.net/th/fishing` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/fishing',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/fishing',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/fishing',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/fishing',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/fishing',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/fishing',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/fishing',
					hrefLang: 'bn-BD',
				},
			],
		},
		id: {
			title: `Nova88 | Tembak Ikan Online | Permainan Tembak Ikan Terbaik`,
			description: `Tembak Ikan Online Di Nova88. Pilih Room Terbaik, Tembak Jatuh Berbagai Makhluk Laut Untuk Mengumpulkan Poin Dan Hadiah.`,
			keyword: `Game Tembak Ikan, Tembak ikan Online, Casino Tembak Ikan, Tembak Ikan Uang Asli, Tembak Ikan Arcade, Perang Tembak Ikan, Fish Haiba, Happy Fish 5, Monster Awaken, Fishing World, Paradise`,
			links: [
				{ rel: 'canonical', href: `https://www.nova88.net/id/fishing` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/fishing',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/fishing',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/fishing',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/fishing',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/fishing',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/fishing',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/fishing',
					hrefLang: 'bn-BD',
				},
			],
		},
		hi: {
			title: `Nova88 | मत्स्य पालन ऑनलाइन | सर्वश्रेष्ठ मत्स्य पालन गेम्स`,
			description: `Nova88 में ऑनलाइन फिशिंग करें, समुद्र की गहराई में खुद को डुबोएं। कुछ रसीले चारे का चयन करें, अंक और पुरस्कार लेने के लिए विभिन्न समुद्री जीवों को मार गिराएं।`,
			keyword: `फिशिंग गेम, फिशिंग ऑनलाइन, कैसिनो फिशिंग गेम, फिशिंग गेम रियल मनी, आर्केड फिशिंग गेम, फिशिंग वॉर, फिश हाइबा, हैप्पी फिश 5, मॉन्स्टर अवेकन, फिशिंग वर्ल्ड, पैराडाइज`,
			links: [
				{ rel: 'canonical', href: `https://www.nova88.net/hi/fishing` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/fishing',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/fishing',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/fishing',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/fishing',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/fishing',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/fishing',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/fishing',
					hrefLang: 'bn-BD',
				},
			],
		},
		cs: {
			title: `Nova88 | 线上捕鱼 | 亚洲最佳捕鱼游戏`,
			description: `Nova88在线捕鱼游戏让你如身临其境的享受捕鱼乐趣！赶紧带上你的鱼饵，捕获各种海洋生物以获取高额奖励！`,
			keyword: `捕鱼游戏, 在线捕鱼, 赌场捕鱼游戏, 真钱捕鱼游戏, 街机捕鱼游戏, 捕鱼大战, 海霸, 开心捕鱼5, 海王3, 捕鱼天下, 皇金渔场`,
			links: [
				{ rel: 'canonical', href: `https://www.nova88.net/cs/fishing` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/fishing',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/fishing',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/fishing',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/fishing',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/fishing',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/fishing',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/fishing',
					hrefLang: 'bn-BD',
				},
			],
		},
		bd: {
			title: `নোভা ৮৮, ফিশিং অনলাইন, সবচেয়ে ভালো ফিশিং গেমস `,
			description: `নোভা৮৮এ অনলাইনে মাছ ধরা, নিজেকে সমুদ্রের গভীরে নিমজ্জিত করুন। কিছু সরস টোপ নির্বাচন করুন, পয়েন্ট এবং পুরষ্কার সংগ্রহ করতে বিভিন্ন সমুদ্রের প্রাণীকে গুলি করুন`,
			keyword: `মাছ ধরা গেম, মাছ ধরা অনলাইন, ক্যাসিনো মাছ ধরা গেম, ফিশিং গেম রিয়েল মানি, আর্কেড মাছ ধরা গেম, মাছ ধরা ওয়ার, ফিশ হাইবা, হ্যাপি ফিশ 5, মনস্টার জাগ্রত,মাছ ধরা ওয়ার্ল্ড, প্যারাডাইস`,
			links: [
				{ rel: 'canonical', href: `https://www.nova88.net/bd/fishing` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/fishing',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/fishing',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/fishing',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/fishing',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/fishing',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/fishing',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/fishing',
					hrefLang: 'bn-BD',
				},
			],
		},
		kh: {
			title: `Nova88 |  ហ្គេមស្ទូចត្រីអនឡាញ |  ហ្គេមស្ទូចត្រីល្អបំផុត`,
			description: `ការស្ទូចត្រីអនឡាញនៅ Nova88 នាំលោកអ្នកជ្រមុជទៅក្នុងទឹកជ្រៅនៃសមុទ្រ។  ជ្រើសរើសនុយ Juicyបាញ់សត្វសមុទ្រផ្សេងៗ ដើម្បីប្រមូលពិន្ទុ និងរង្វាន់។`,
			keyword: `ហ្គេមស្ទូចត្រី, ហ្គេមស្ទូចត្រីអនឡាញ, កាស៊ីណូហ្គេមស្ទូចត្រី, ហ្គេមស្ទូចត្រីលុយពិត, Arcade Fishing Game, Fishing War, Fish Haiba, Happy Fish 5, Monster Awaken, Fishing World, Paradise`,
			links: [
				{ rel: 'canonical', href: `https://www.nova88.net/kh/fishing` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/fishing',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/fishing',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/fishing',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/fishing',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/fishing',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/fishing',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/fishing',
					hrefLang: 'bn-BD',
				},
			],
		},
		vn: {
			title: `Nova88 | Bắn cá online | Trò chơi bắn cá hàng đầu`,
			description: `Chơi bắn cá online tại Nova88, trải nghiệm cảm giác chân thực khi phiêu lưu vào lòng đại dương, bắn hạ các siêu thủy quái khổng lồ, tích điểm và nhận thưởng khủng!`,
			keyword: `Bắn cá, bắn cá online, bắn cá sòng bài, trò chơi bắn cá, bắn cá ăn tiền thật, Fishing War, Fish Haiba, Happy Fish 5, Monster Awaken, Fishing World, Dragonball Fishing, Paradise`,
			links: [
				{ rel: 'canonical', href: `https://www.nova88.net/vn/fishing` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/fishing',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/fishing',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/fishing',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/fishing',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/fishing',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/fishing',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/fishing',
					hrefLang: 'bn-BD',
				},
			],
		},
		ko: {
			title: `Nova88 | 온라인 낚시 | 최고의 낚시 게임`,
			description: `Nova88에는 가장 기대되는 최신 낚시 게임이 있습니다. 깊은 바다에 몰두하여 다양한 해양 생물을 사냥하여 포인트와 보상을 모으세요!`,
			keyword: `낚시 게임, 온라인 낚시, 카지노 낚시 게임, 리얼 머니 낚시 게임, 아케이드 낚시 게임, 낚시 대전`,
			links: [
				{ rel: 'canonical', href: `https://www.nova88.net/ko/fishing` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/fishing',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/fishing',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/fishing',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/fishing',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/fishing',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/fishing',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/fishing',
					hrefLang: 'bn-BD',
				},
			],
		},
		br: {
			title: `Nova88 | Pesca Online | Melhores Jogos de Pesca`,
			description: `A Nova88 possui os jogos de pesca mais esperados e recentes. Mergulhe nas profundezas do mar, colete pontos e recompensas atirando em várias criaturas marinhas!`,
			keyword: `Jogo de Pesca, Pesca Online, Jogo de Pesca de Cassino, Jogo de Pesca por Dinheiro Real, Jogo de Pesca Arcade, Guerra de Pesca`,
			links: [
				{ rel: 'canonical', href: `https://www.nova88.net/br/fishing` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/fishing',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/fishing',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/fishing',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/fishing',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/fishing',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/fishing',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/fishing',
					hrefLang: 'bn-BD',
				},
			],
		},
	},
	[paths.lottery.root]: {
		en: {
			title: `Nova88 | Online Lottery | Casino Game`,
			description: `Nova88 Offer Keno, Lottery, Number Game And Table Game That Are Fast, Fun and Easy To Play. Each Draw Last Only A Few Minutes And Settlement Is Fast.`,
			keyword: `Lottery Online, Lottery, Number Game, Table Game, TC Gaming, Keno, Real Money Casino Game`,
			links: [
				{ rel: 'canonical', href: `https://www.nova88.net/en/lottery` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/lottery',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/lottery',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/lottery',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/lottery',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/lottery',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/lottery',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/lottery',
					hrefLang: 'bn-BD',
				},
			],
		},
		th: {
			title: `Nova88 | หวย ออนไลน์ | เกมส์คาสิโน`,
			description: `Nova88 คีโน หวย เกมตัวเลข และเกมโต๊ะที่รวดเร็ว สนุก และเล่นง่าย การออกรางวัลแต่ละครั้งใช้เวลาเพียงไม่กี่นาทีและการปรับยอดอย่างรวดเร็ว`,
			keyword: `หวยออนไลน์, หวย, เกมหมายเลข, เกมโต๊ะ, หวยไทย TC gaming, คีโน, เกมคาสิโนเล่นเงินจริง`,
			links: [
				{ rel: 'canonical', href: `https://www.nova88.net/th/lottery` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/lottery',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/lottery',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/lottery',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/lottery',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/lottery',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/lottery',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/lottery',
					hrefLang: 'bn-BD',
				},
			],
		},
		id: {
			title: `Nova88 | Togel Online | Casino Online`,
			description: `Nova88 Menawarkan Permainan Keno, Togel, Number Game Dan Permainan Meja Yang Cepat, Menyenangkan Dan Mudah Dimainkan. Setiap Undian Hanya Berlangsung Beberapa Menit Dan Withdraw Juga Cepat.`,
			keyword: `Togel Online, Togel, Number Game, Table Game, Permainan TC, Keno, Game Casino Uang Asli`,
			links: [
				{ rel: 'canonical', href: `https://www.nova88.net/id/lottery` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/lottery',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/lottery',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/lottery',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/lottery',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/lottery',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/lottery',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/lottery',
					hrefLang: 'bn-BD',
				},
			],
		},
		hi: {
			title: `Nova88 | ऑनलाइन लॉटरी | कैसीनो गेम्स`,
			description: `Nova88 पेश करता है केनो, लॉटरी, नंबर गेम और टेबल गेम जो तेज़, मज़ेदार और खेलने में आसान हैं। प्रत्येक ड्रा केवल कुछ मिनटों तक रहता है और निपटान तेज़ है।`,
			keyword: `लॉटरी ऑनलाइन, लॉटरी, नंबर गेम, टेबल गेम, टीसी गेमिंग, केनो, रियल मनी कैसीनो गेम`,
			links: [
				{ rel: 'canonical', href: `https://www.nova88.net/hi/lottery` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/lottery',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/lottery',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/lottery',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/lottery',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/lottery',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/lottery',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/lottery',
					hrefLang: 'bn-BD',
				},
			],
		},
		cs: {
			title: `Nova88 | 线上彩票 | 真人娱乐游戏`,
			description: `Nova88 提供节奏快速、有趣且容易上手的 彩票类和桌面游戏。开奖结算时间几分钟内完成。`,
			keyword: `在线彩票、彩票、数字游戏、桌面游戏、TC Gaming、Keno、真钱赌场游戏`,
			links: [
				{ rel: 'canonical', href: `https://www.nova88.net/cs/lottery` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/lottery',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/lottery',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/lottery',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/lottery',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/lottery',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/lottery',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/lottery',
					hrefLang: 'bn-BD',
				},
			],
		},
		bd: {
			title: `নোভা ৮৮, অনলাইন লটারি, ক্যাসিনো গেমস `,
			description: `নোভা৮৮ অফার করে কেনো , লটারি, নম্বর গেম এবং টেবিল গেম যা দ্রুত, মজাদার এবং খেলার জন্য সহজ। প্রতিটি ড্র মাত্র কয়েক মিনিট স্থায়ী হয় এবং নিষ্পত্তি দ্রুত হয়`,
			keyword: `লটারি অনলাইন, লটারি, নম্বর গেম, টেবিল গেম, টিসি গেমিং, কেনো, রিয়েল মানি ক্যাসিনো গেম`,
			links: [
				{ rel: 'canonical', href: `https://www.nova88.net/bd/lottery` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/lottery',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/lottery',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/lottery',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/lottery',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/lottery',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/lottery',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/lottery',
					hrefLang: 'bn-BD',
				},
			],
		},
		kh: {
			title: `Nova88 |  ឆ្នោតអនឡាញ |  ហ្គេមកាស៊ីណូ`,
			description: `Nova88 ផ្តល់ជូន Keno ឆ្នោត ហ្គេមលេខ និង ហ្គេមលើតុ ដែលលឿន សប្បាយ និងងាយស្រួលលេង។ ការចាប់ឆ្នោតនីមួយៗមានរយៈពេលត្រឹមតែពីរបីនាទីប៉ុណ្ណោះ ហើយការទូទាត់គឺលឿន។`,
			keyword: `"ឆ្នោតអនឡាញ, ឆ្នោត, ហ្គេមលេខ, ហ្គេមលើតុ, TC Gaming, Keno, ហ្គេមកាស៊ីណូលុយពិត"`,
			links: [
				{ rel: 'canonical', href: `https://www.nova88.net/kh/lottery` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/lottery',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/lottery',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/lottery',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/lottery',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/lottery',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/lottery',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/lottery',
					hrefLang: 'bn-BD',
				},
			],
		},
		vn: {
			title: `Nova88 | Xổ số trực tuyến | Game casino`,
			description: `Nova88 có Keno, xổ số, game số, và game bàn nhanh chóng, hứng khởi, dễ chơi, và đặc biệt là chỉ kéo dài vài phút, bạn có thể thắng cược ngay. `,
			keyword: `Xổ số online, xổ số, game số, game bàn, TC Gaming, Keno, game casino ăn tiền thật, xổ số malaysia online`,
			links: [
				{ rel: 'canonical', href: `https://www.nova88.net/vn/lottery` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/lottery',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/lottery',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/lottery',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/lottery',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/lottery',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/lottery',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/lottery',
					hrefLang: 'bn-BD',
				},
			],
		},
		ko: {
			title: `Nova88 | 온라인 복권 | 카지노 게임`,
			description: `복권은 당신의 운을 시험하는 가장 클래식한 게임 중 하나입니다. Nova88은(는) 매일 복권 추첨을 제공하여 플레이어들에게 큰 상금을 획득할 기회를 제공합니다.`,
			keyword: `온라인 복권, 복권, 숫자 게임, 테이블 게임, 키노, 리얼 머니 카지노 게임`,
			links: [
				{ rel: 'canonical', href: `https://www.nova88.net/ko/lottery` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/lottery',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/lottery',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/lottery',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/lottery',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/lottery',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/lottery',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/lottery',
					hrefLang: 'bn-BD',
				},
			],
		},
		br: {
			title: `Nova88 | Loteria Online | Jogo de Cassino`,
			description: `A loteria é um dos jogos mais clássicos para testar sua sorte. A Nova88 oferece um sorteio diário de loteria, dando aos jogadores a chance de ganhar grandes prêmios.`,
			keyword: `Loteria Online, Loteria, Jogo de Números, Jogo de Mesa, Quina, Jogo de Cassino por Dinheiro Real`,
			links: [
				{ rel: 'canonical', href: `https://www.nova88.net/br/lottery` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/lottery',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/lottery',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/lottery',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/lottery',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/lottery',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/lottery',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/lottery',
					hrefLang: 'bn-BD',
				},
			],
		},
	},
	// SEO 等市場補上
	[paths.europeCup.root]: {
		en: {
			title: `Nova88 | Online Lottery | Casino Game`,
			description: `Nova88 Offer Keno, Lottery, Number Game And Table Game That Are Fast, Fun and Easy To Play. Each Draw Last Only A Few Minutes And Settlement Is Fast.`,
			keyword: `Lottery Online, Lottery, Number Game, Table Game, TC Gaming, Keno, Real Money Casino Game`,
			links: [
				{ rel: 'canonical', href: `https://www.nova88.net/en/lottery` },
				{
					rel: 'alternate',
					href: 'https://www.nova88.net/en/lottery',
					hrefLang: 'en-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88mas.com/my/lottery',
					hrefLang: 'ms-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88-indo805.info/id/lottery',
					hrefLang: 'id-ID',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88bkk.com/th/lottery',
					hrefLang: 'th-TH',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88india.com/hi/lottery',
					hrefLang: 'hi-IN',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88105.net/cs/lottery',
					hrefLang: 'zh-MY',
				},
				{
					rel: 'alternate',
					href: 'https://www.nova88106.net/bd/lottery',
					hrefLang: 'bn-BD',
				},
			],
		},
	},
}
