import { useState } from 'react'
import { useTranslation } from 'next-i18next'

/**
 * @returns 回傳圖片檔案
 */
const useImageFile = () => {
	const { t } = useTranslation()
	const [imageFile, setImageFile] = useState<File | null>(null)
	const [errorMessage, setErrorMessage] = useState('')

	const onImageFileChange = (
		event:
			| React.ChangeEvent<HTMLInputElement>
			| React.DragEvent<HTMLLabelElement>
	) => {
		const file =
			(event as React.DragEvent<HTMLLabelElement>).dataTransfer?.files?.length >
			0
				? (event as React.DragEvent<HTMLLabelElement>).dataTransfer?.files[0]
				: (event as React.ChangeEvent<HTMLInputElement>).target?.files?.[0]
		if (file && file.type.startsWith('image/')) {
			setImageFile(file)
			setErrorMessage('')
		} else {
			setErrorMessage(
				t('Label_History_File_format_error_please_try_again') as string
			)
			setImageFile(null)
		}
	}

	return { errorMessage, imageFile, setImageFile, onImageFileChange }
}

export { useImageFile }
