export const theme = {
	assetUrl: process.env['NEXT_PUBLIC_BANNER_HOST'],
	common: {},
	desktop: {
		minContentWidth: '120rem',
		contentWidth: '140rem',
		smallBannerContainerHeight: '33.2rem',
		largeBannerContainerHeight: '39rem',
		smallBannerHeight: '31.2rem',
		largeBannerHeight: '36.4rem',

		primary: '#0F1428',
		hrefColorPrimary: '#FF813D',
		white: '#fff',
		inputBorderColorError: '#FF4040',
		tipColorError: '#FF4040',
		tipColor: '#7883B4',
		fontFamily: `'Montserrat', serif`,

		headerBg: '#0F1428',
		headerColor: '#7883B4',
		headerInputBg: '#232b4f',
		headerInputPlaceHolderColor: '#7883B4',
		headerInputColor: '#fff',
		headerLineLoginBtnBg: '#01B901',
		headerLineLoginBtnColor: '#fff',
		headerLineLoginBtnShadow:
			'0px -2px 0px 0px rgba(255, 255, 255, 0.40) inset',
		headerLineLoginBgHover: '#009800',
		headerForgotPasswordColor: '#7883B4',
		headerForgotPasswordColorHover: '#fff',
		countryCurrentColor: '#FFF',
		countryCurrentBtnGroupBg: '#232B4F',
		countryCurrentBtnBg: '#374375',
		countryCurrentBtnColor: '#939DC5',
		countryCurrentBtnBgHover: '#FF813D',
		countryCurrentBtnColorHover: '#fff',
		gradeTextColor: '#fff',
		gradeVintageBg: '#e1884c',
		gradeVintageColor: '#fff',
		gradeSilverBg: '#ADADAD',
		gradeSilverColor: '#fff',
		gradeGoldBg: '#E8BB4A',
		gradeGoldColor: '#fff',
		gradeTitaniumBg: '#606677',
		gradeTitaniumColor: '#fff',
		gradeDiamondBg: '#6DBDF2',
		gradeDiamondColor: '#fff',
		gradeNovaBg: '#BCACA3',
		gradeNovaColor: '#fff',
		gradeInfinityBg: '#BCACA3',
		gradeInfinityColor: '#fff',
		headerBalanceColor: '#fff',
		depositBtnBg: '#FF813D',
		depositBtnColor: '#fff',
		depositBtnBgHover: '#FF813D',
		depositBtnColorHover: '#fff',
		depositBtnBoxShadow: '0px -2px 0px 0px rgba(255, 255, 255, 0.4) inset',
		homeProfileProgressBg: '#374375',
		homeProfileProgressBarBg: '#FF813D',

		footerBg: '#232B4F',
		copyRightBg: '#1F274C',

		primaryBtnBg: '#FF813D',
		primaryBtnColor: '#fff',
		primaryBtnShadow: '0px -2px 0px 0px rgba(255, 255, 255, 0.40) inset',
		primaryBtnBgHover: '#D95712',
		primaryBtnColorHover: '#fff',
		primaryBtnShadowHover: 'none',

		secondaryBtnBg: '#717FBA',
		secondaryBtnColor: '#fff',
		secondaryBtnShadow: '0px -2px 0px 0px rgba(255, 255, 255, 0.40) inset',
		secondaryBtnBgHover: '#5E6DAC',
		secondaryBtnColorHover: '#fff',
		secondaryBtnShadowHover: 'none',

		tabsBtnBg: '#374375',
		tabsBtnColor: '#939DC5',
		tabsBtnHoverBg: '#717FBA',
		tabsBtnHoverColor: '#fff',

		disabledBtnBg: '#505667',
		disabledBtnColor: '#6F7689',
		disabledBtnShadow: '0px -2px 0px 0px rgba(255, 255, 255, 0.40) inset;',

		dropdownBg: '#182043',
		dropdownTextColor: '#939DC5',
		dropdownLineColor: '#374375',
		dropdownShadow: '0px 6px 30px 0px rgba(0, 0, 0, 0.50)',
		dropdownHeaderBg: '#232B4F',
		dropdownHeaderColor: '#939DC5',
		dropdownMainBg: '#374375',
		dropdownItemBg: '#182043',
		dropdownItemColor: '#939DC5',
		dropdownItemColorHover: '#fff',

		menuContainerBg: '#232B4F',
		menuItemColor: '#fff',
		menuItemBgHover: '#182043',
		menuItemColorHover: '#FF813D',
		menuItemActiveLine: '#FF813D',
		newLabelBg: '#FF4040',
		newLabelColor: '#fff',
		hotLabelBg: '#e12973',
		hotLabelColor: '#fff',
		hotIconColor: '#ffd21d',

		marqueeMessageBg: '#374375',
		marqueeMessageColor: '#fff',
		liveChatColor: '#FF813D',

		bannerBulletBg: '#374375',
		bannerBulletBgActive: '#FF813D',

		welcomeInfoTitleColor: '#fff',
		welcomeInfoDescColor: '#fff',

		welcomeAffiliateBtnBg: '#0a328e',
		welcomeAffiliateBtnBgHover: '#0B39A3',
		welcomeAffiliateBtnColor: '#6da5ff',
		welcomeAffiliateBtnColorHover: '#fff',
		welcomeAffiliateBtnShadow: '0px -7px 0px 0px #1155cb inset',
		welcomeAffiliateBtnShadowHover: '0px -7px 0px 0px #1155CB inset',

		welcomeWinningBtnBg: '#20611e',
		welcomeWinningBtnBgHover: '#297A26',
		welcomeWinningBtnColor: '#8de78a',
		welcomeWinningBtnColorHover: '#fff',
		welcomeWinningBtnShadow: '0px -7px 0px 0px #37a433 inset',
		welcomeWinningBtnShadowHover: '0px -7px 0px 0px #37A433 inset',

		welcomeRewardBtnBg: '#b52c00',
		welcomeRewardBtnBgHover: '#CF3300',
		welcomeRewardBtnColor: '#ffaa8e',
		welcomeRewardBtnColorHover: '#fff',
		welcomeRewardBtnShadow: '0px -7px 0px 0px #dd4c00 inset',
		welcomeRewardBtnShadowHover: '0px -7px 0px 0px #DD4C00  inset',

		welcomeReferralBtnBg: '#741a99',
		welcomeReferralBtnBgHover: '#881EB2',
		welcomeReferralBtnColor: '#e6aaff',
		welcomeReferralBtnColorHover: '#fff',
		welcomeReferralBtnShadow: '0px -7px 0px 0px #b82dd0 inset',
		welcomeReferralBtnShadowHover: '0px -7px 0px 0px #B82DD0 inset',

		homePageTitleColor: '#fff',
		homePageLoadMoreColor: '#717FBA',
		homePageLoadMoreColorHover: '#fff',

		carouselBtnBg: '#374375',
		carouselBtnBorder: '#0f1428',
		carouselBtnColor: '#fff',
		carouselBtnBgHover: '#48558B',
		carouselBtnBorderHover: '#0f1428',
		carouselBtnColorHover: '#fff',
		carouselBtnColorDisabled: '#717fba',

		gameIconBg: '#1D2447',
		gameIconBgHover: 'rgba(0, 0, 0, 0.5)',
		gameIconLoadingColor: '#fff',
		gameIconProviderNameColor: '#fff',
		gameIconMaintainBg:
			'linear-gradient(180deg, rgba(35, 43, 79, 0.00) 0%, rgba(35, 43, 79, 0.70) 25.5%, rgba(35, 43, 79, 0.70) 75%, rgba(35, 43, 79, 0.00) 100%)',
		gameIconMaintainColor: '#fff',
		gameIconNoGameIconColor: '#374375',
		gameIconNewBg: '#FF4040',
		gameIconNewColor: '#fff',
		gameIconNewShadow: '0px 2.286px 0px 0px rgba(0, 0, 0, 0.25)',
		gameIconFavoriteColor: '#fff',
		gameIconFavoriteColorActive: '#fbc208',

		hotMatchBg: '#374375',
		hotMatchShadow: '0px -5px 0px 0px #323c6a inset',
		hotMatchHeaderBg: '#232b4f',
		hotMatchHeaderColor: '#fff',
		hotMatchLogoBg: '#dfdfdf',
		hotMatchOddsBg: '#232b4f',
		hotMatchOddsColor: '#fff',
		hotMatchAwayOddsColor: '#3b7bf0',
		hotMatchDateColor: '#fff',
		hotMatchBtnBg: '#FF813D',
		hotMatchBtnShadow: '0px -5px 0px 0px #d35716 inset',
		hotMatchBtnColor: '#fff',
		hotMatchBtnBgHover: '#f76b20',
		hotMatchBtnShadowHover: '0px -5px 0px 0px #d35716 inset',

		swiperBtnBg: '#FF813D',
		swiperBtnBorder: '2px solid #0f1428',

		filterFormBg: '#232b4f',
		filterFormInputBg: '#374375',
		filterFormInputColor: '#fff',
		filterFormInputPlaceHolderColor: '#7883b4',
		filterFormProviderBg: '#374375',
		filterFormProviderBgHover:
			'linear-gradient(0deg, #717fba 0%, #a2afe5 100%)',
		filterFormProviderColor: '#fff',
		filterFormProviderLogoBg: '#dcdee9',

		gameTabBorder: '1px solid #556298',
		gameTabItemColor: '#717fba',
		gameTabItemColorHover: '#fff',
		gameTabItemBorderHover: '2px solid var(--Color-5, #FF813D)',

		buttonSloganBg: '#FF813D',
		registerBodyBg: '#182043',

		inputBg: '#232b4f',
		inputPlaceHolderColor: '#7883B4',
		inputColor: '#fff',

		registerInputDropDownShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
		registerInputDropDownColor: '#939DC5',
		registerInputDropDownColorHover: '#FF813D',
		registerDropdownBg: '#182043',
		registerDropdownBorderColor: '#374375',

		popupBoxShadow:
			'0px 0px 10px 0px rgba(0, 0, 0, 0.25), 0px 2px 10px 0px rgba(0, 0, 0, 0.10)',
		popupBg: '#0F1428',

		leftMenuHeaderBgAfterLogin: '#232B4F',
		leftMenuBodyBgAfterLogin: '#182043',
		leftMenuItemColorAfterLogin: '#939DC5',
		leftMenuItemColorActiveAfterLogin: '#FF813D',
		leftMenuItemBoxShadowActiveAfterLogin: '-4px 0px 0px 0px #FF813D inset',
		leftMenuBorderColor: '#374375',

		rightModuleBg: '#182043',
		rightModuleTitleBg: '#232B4F',
		rightModuleBorderColor: '#374375',
		rightModuleSloganColor: '#939DC5',

		vipBg: '#E1884C',
		userVertificationDefaultBgIcon: '#374375',
		userVertificationActiveBgIcon: '#717FBA',

		tooltipBorderColor: '#FF813D',
		tooltipBgColor: '#232B4F',
		tooltipColor: '#FF813D',
		tooltipBoxShadow: '0px 6px 30px 0px rgba(0, 0, 0, 0.50)',
		labelColor: '#939DC5',

		bankBgColor: '#232B4F',
		borderColorBankAccount: '#374375',
		btnDepositAmountBg: '#374375',
		btnDepositAmountColor: '#939DC5',
		btnDepositAmountBgDisabled: '#232B4F',
		btnDepositAmountColorDisabled: '#374375',

		importantBg: '#232B4F',
		borderAccountBank: '#374375',
		bgUploadFileButton: '#1D2447',
		bgAddNewModalButton: '#374375',
		infoUserAccountBankBorderInputColor: '#232b4f',
		infoUserAccountBankBgInputColor: '#1d2447',
		infoUserAccountBankLabelColor: '#939DC5',
		infoUserAccountBankUserNameColor: '#fff',

		borderOutLine: '#232b4f',
		bgOutLine: '#1D2447',
		labelOutLineColor: '#939DC5',

		switchBgOff: '#505667',
		switchBgOn: '#01B901',

		customTableTheadBg: '#232B4F',
		customTableBorderColor: '#374375',
		customTableTbodyBg: '#182043',

		borderIconNotifiCationColor: '#182043',
		bgIconNotifiCationColor: '#1D2447',
		iconNotifiCationColor: '#939DC5',
		hoverAddBankName: '#232B4F',

		tableStatusPenddingColor: '#FBC208',
		tableStatusRejetctColor: '#FF4040',
		tableStatusApprovedColor: '#01B901',
		tableStatusCancelColor: '#6F7689',

		paginationBg: '#717FBA',
		paginationColor: '#939DC5',

		bradcumbColor: '#939DC5',

		borderCardRederralColor: '#374375',
		bgCardRederralColor: '#232B4F',

		datePickerSelectColor: '#FF813D',
		datePickerSelectRangeBg: '#717FBA',
		datePickerHeaderBg: '#0F1428',
		datePickerBoxShadow: '0px 6px 30px 0px rgba(0, 0, 0, 0.50)',

		promotionTabsBodyBg: '#232B4F',
		promotionNavBg: '#374375',
		promotionNavActiveBg: 'linear-gradient(0deg, #717FBA 0%, #A2AFE5 100%)',
		promotionItemsBg: '#2E3965',
		promotionItemBgHover: '#374375',
		promotionBorderItems: '#717FBA',
		promotionTimeBg: '#374375',
		promotionTimeBgHover: '#414F86',

		vipDiamonBtnBg: '#DEF2FF',
		vipDiamonBtnColor: '#6DBDF2',
		vipDiamonTitleBgColor: '#6DBDF2',

		vipTitaniumBtnBg: '#C6C9CB',
		vipTitaniumBtnColor: '#606677',
		vipTitaniumTitleBgColor: '#606677',

		vipInfinityBtnBg: '#F5F5F5',
		vipInfinityBtnColor: '#BCACA3',
		vipInfinityTitleBgColor: '#BCACA3',

		vipVintageBtnBg: '#FFF2EA',
		vipVintageBtnColor: '#FAA972',
		vipVintageTitleBgColor: '#FAA972',

		vipSilverBtnBg: '#E5E5E5',
		vipSilverBtnColor: '#ADADAD',
		vipSilverTitleBgColor: '#ADADAD',

		vipGoldBtnBg: '#F8EDD1',
		vipGoldBtnColor: '#E8BB4A',
		vipGoldTitleBgColor: '#E8BB4A',
	},
	mobile: {
		headerBg: '#0F1428',
		headerColor: '#fff',

		menuContainerBg: '#232B4F',
		menuItemColor: '#fff',
		menuItemBgHover: '#182043',
		menuItemColorHover: '#FF813D',
		menuItemActiveLine: '#FF813D',
		newLabelBg: '#FF4040',
		newLabelColor: '#fff',
		hotLabelBg: '#e12973',
		hotLabelColor: '#fff',
		hotIconColor: '#ffd21d',

		marqueeMessageBg: '#374375',
		marqueeMessageColor: '#fff',

		bannerBulletBg: '#374375',
		bannerBulletBgActive: '#FF813D',

		homePageTitleColor: '#fff',
		homePageLoadMoreColor: '#717FBA',

		carouselBtnBg: '#374375',
		carouselBtnBorder: '#0f1428',
		carouselBtnColor: '#fff',
		carouselBtnColorDisabled: '#717fba',

		gameIconBg: '#1D2447',
		gameIconLoadingColor: '#fff',
		gameIconProviderNameColor: '#fff',
		gameIconMaintainBg:
			'linear-gradient(180deg, rgba(35, 43, 79, 0.00) 0%, rgba(35, 43, 79, 0.70) 25.5%, rgba(35, 43, 79, 0.70) 75%, rgba(35, 43, 79, 0.00) 100%)',
		gameIconMaintainColor: '#fff',
		gameIconNoGameIconColor: '#374375',
		gameIconNewBg: '#FF4040',
		gameIconNewColor: '#fff',
		gameIconNewShadow: '0px 2.286px 0px 0px rgba(0, 0, 0, 0.25)',
		gameIconFavoriteColor: '#fff',
		gameIconFavoriteColorActive: '#fbc208',

		hotMatchBg: '#374375',
		hotMatchShadow: '0px -5px 0px 0px #323c6a inset',
		hotMatchHeaderBg: '#232b4f',
		hotMatchHeaderColor: '#fff',
		hotMatchLogoBg: '#dfdfdf',
		hotMatchOddsBg: '#232b4f',
		hotMatchOddsColor: '#fff',
		hotMatchAwayOddsColor: '#3b7bf0',
		hotMatchDateColor: '#fff',
		hotMatchBtnBg: '#FF813D',
		hotMatchBtnShadow: '0px -5px 0px 0px #d35716 inset',
		hotMatchBtnColor: '#fff',

		breadcrumbTextColor: '#939dc5',
		breadcrumbLineColor: '#374375',

		rightModuleBg: '#182043',
		rightModuleTitleBg: '#232B4F',
		rightModuleBorderColor: '#374375',
		rightModuleSloganColor: '#939DC5',

		bgFooter: '#232B4F',

		hrefColorPrimary: '#FF813D',
		bgLoginRegisterColor: '#0F1428',
		white: '#fff',
		boxShadowLoginRegisterColor: '0px 2px 10px 0px rgba(0, 0, 0, 0.10)',
		bgSwitchButtonLoginColor: '#232B4F',
		switchButtonLoginColor: '#7883B4',
		switchButtonLoginColorActive: '#fff',
		switchButtonBgActive: '#374375',
		switchButtonBoxShadowActive:
			'0px -2px 0px 0px rgba(255, 255, 255, 0.40) inset',
		stepBg: '#374375',
		stepBorderActive: 'rgba(234, 97, 24, 0.5)',
		stepBgActive: '#FF813D',
		stepBgItem: '#717FBA',
		stepBoder: '#374375',

		tooltipBorderColor: '#FF813D',
		tooltipBgColor: '#232B4F',
		tooltipColor: '#FF813D',
		tooltipBoxShadow: '0px 6px 30px 0px rgba(0, 0, 0, 0.50)',

		vipDiamonBtnBg: '#DEF2FF',
		vipDiamonBtnColor: '#6DBDF2',
		vipDiamonTitleBgColor: '#6DBDF2',

		vipTitaniumBtnBg: '#C6C9CB',
		vipTitaniumBtnColor: '#606677',
		vipTitaniumTitleBgColor: '#606677',

		vipInfinityBtnBg: '#F5F5F5',
		vipInfinityBtnColor: '#BCACA3',
		vipInfinityTitleBgColor: '#BCACA3',

		vipVintageBtnBg: '#FFF2EA',
		vipVintageBtnColor: '#FAA972',
		vipVintageTitleBgColor: '#FAA972',

		vipSilverBtnBg: '#E5E5E5',
		vipSilverBtnColor: '#ADADAD',
		vipSilverTitleBgColor: '#ADADAD',

		vipGoldBtnBg: '#F8EDD1',
		vipGoldBtnColor: '#E8BB4A',
		vipGoldTitleBgColor: '#E8BB4A',

		promotionHeaderGroupBg: '#232b4f',
		promotionHeaderGroupColor: '#fff',
		promotionGroupBg: '#182043',
		promotionGroupColor: '#939dc5',
		promotionGroupBorder: '#374375',
		promotionLinkColor: '#717fba',
	},
}
