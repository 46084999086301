import { useGlobalModal } from '@nx-next-app/components/config-provider'
import { useAuth, useDepositSavedLocalStorage } from '@nx-next-app/data-access'
import {
	IAxiosError,
	IPaymentAutoDepositData,
	useGetPaymentCustBankInfoQuery,
	useLazyCheckDepositSpecialCodeQuery,
} from '@nx-next-app/service'
import { DepositExtraDataTypeEnum } from '@nx-next-app/types'
import { isConvert, scrollToTop } from '@nx-next-app/utils'
import { every, find, head, isEmpty } from 'lodash-es'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useBoolean, useDebounce } from 'usehooks-ts'
import { useDepositContext } from './DepositContext'

interface IAutoDepositForm {
	amount: number
	sysId?: number
	channelId?: number
	minAmount?: number
	maxAmount?: number
	custBankAccount?: string
	bonusCode?: string
	promoCode?: string
	amountRule?: number
	amountList?: number[] | null
}

const useAutoDepositForm = ({
	dataSource,
}: {
	dataSource: IPaymentAutoDepositData[]
}) => {
	const { t } = useTranslation()
	const {
		userInfo: { langId, currencyId },
	} = useAuth()
	const { modal } = useGlobalModal()
	const { value: isFirstEffect, toggle: toggleIsFirstEffect } = useBoolean(true)
	const { query, pathname, push } = useRouter()
	const {
		paymentType: queryPaymentType,
		channelId: queryChannelId,
		sysId: querySysId,
	} = query

	const {
		loading: contextLoading,
		refetchPaymentAutoDepositList,
		handleDepositTracker,
	} = useDepositContext()

	const formMethods = useForm<IAutoDepositForm>()

	const { setValue, setError, resetField } = formMethods

	const {
		data: { data: custBankInfo = [] } = {},
		isFetching: isGetCustBankInfoFetching,
	} = useGetPaymentCustBankInfoQuery(
		{ channelId: Number(queryChannelId) },
		{ skip: !queryChannelId }
	)

	const [
		checkDepositSpecialCode,
		{ isFetching: isCheckDepositSpecialCodeFetching },
	] = useLazyCheckDepositSpecialCodeQuery()

	const {
		isDepositLocalStorageSavedChecked,
		depositLocalStorageSavedInfo,
		onSavedDepositLocalStorageSavedInfo,
	} = useDepositSavedLocalStorage()

	const { supportBanks = [], extraDataType } =
		find(dataSource, {
			channelId: Number(queryChannelId),
		}) ?? {}

	const bankCardRequired = extraDataType === DepositExtraDataTypeEnum.bankCard

	const bankCardOptions = custBankInfo
		.filter(({ disabled }) => !disabled)
		.map(({ bankName, accountNo }) => ({
			label: `${bankName} - ${accountNo}`,
			value: accountNo,
		}))

	// * 1. 檢查是否有 specialCode，specialCode 與bonusCode 只能擇一
	// * 2. window.open() only
	// * 3. savedChecked = true 時記住銀行資訊
	const onAutoDepositFormSubmit = async (values: IAutoDepositForm) => {
		handleDepositTracker()
		try {
			const {
				amount = 0,
				channelId = '',
				sysId: bankSysId = '',
				custBankAccount,
				bonusCode: originBonusCode = '',
				promoCode,
			} = values

			// * 如果需選擇銀行卡但未選需跳錯誤提醒
			if (bankCardRequired && !custBankAccount) {
				setError('custBankAccount', {
					message: t('Label_Withdrawal_SelectBankRequired') as string,
				})
				return
			}

			const convertedAmount = isConvert(currencyId) ? amount * 1000 : amount

			let bonusCode: string = originBonusCode
			// * 如果有 promoCode，必須先透過 promoCode 來取得 bonusCode
			if (promoCode) {
				const { data } = await checkDepositSpecialCode({
					specialCode: promoCode,
				}).unwrap()
				if (data?.bonusCode) {
					bonusCode = data?.bonusCode
				}
			}

			// * 如果需要用戶提供銀行卡，則傳入該銀行卡的 sysId，否則傳入一般存款管道銀行 sysId
			let sysId = bankSysId
			if (bankCardRequired)
				sysId = String(
					find(custBankInfo, { accountNo: String(custBankAccount) })?.sysId
				)

			const errorUrl = `${window.location.origin}/404/index_${langId}.html`
			const searchParams = new URLSearchParams({
				ChannelId: String(channelId),
				SysId: String(sysId),
				Amount: String(convertedAmount),
				BonusCode: bonusCode,
				PayAccount: bankCardRequired ? String(custBankAccount) : '',
				errorUrl,
				homeUrl: window.location.origin,
			})
			const paramsUrl = searchParams.toString()

			// * window.open only
			const baseUrl = `${window.location.origin}/${langId}/Cashier/DepositReq`
			window.open(`${baseUrl}?${paramsUrl}`)

			resetField('amount')
			scrollToTop()
			refetchPaymentAutoDepositList()
			if (isDepositLocalStorageSavedChecked) {
				onSavedDepositLocalStorageSavedInfo({
					paymentType: queryPaymentType,
					channelId,
					sysId,
				})
			}
		} catch (error) {
			// * 存款 api 某些情境的錯誤會塞在 rawMessage
			const { rawMessage, message } = error as IAxiosError

			modal.error(rawMessage || message)
		}
	}

	const loading =
		isCheckDepositSpecialCodeFetching ||
		isGetCustBankInfoFetching ||
		contextLoading

	const debouncedLoading = useDebounce(loading, 50)

	// * 任何選項異動時即時更新 formState
	useEffect(() => {
		// * loading 狀態提前 return
		if (debouncedLoading) return

		// * 清空 amount
		resetField('amount')

		const {
			channelId,
			minAmount = 0,
			maxAmount = 0,
			supportBanks,
			amountRule,
			amountList = [],
		} = find(dataSource, { channelId: Number(queryChannelId) }) ||
		head(dataSource) ||
			{}

		const banksInfo =
			find(supportBanks, { sysId: Number(querySysId) }) || head(supportBanks)

		// * 如果 isConvert = true, 畫面上的金額需除 1000 並需要加上 ('000) 的貨幣格式
		// * minAmount 與 maxAmount 優先使用 banksInfo > dataSource
		const convertMinAmount =
			(banksInfo?.minAmount || minAmount) / (isConvert(currencyId) ? 1000 : 1)
		const convertMaxAmount =
			(banksInfo?.maxAmount || maxAmount) / (isConvert(currencyId) ? 1000 : 1)

		setValue('channelId', channelId)
		setValue('minAmount', convertMinAmount)
		setValue('maxAmount', convertMaxAmount)
		setValue('sysId', banksInfo?.sysId)
		setValue('amountRule', amountRule)
		setValue('amountList', amountList)

		let url = {
			pathname,
			query: {
				paymentType: queryPaymentType,
				channelId,
				sysId: banksInfo?.sysId,
			},
		}

		// * 如果當前 isDepositLocalStorageSavedChecked = true 及 depositLocalStorageSavedInfo 有值 及 query url (除了 paymentType 之外)需要的 key 皆為空值時給予 local storage 的值
		// * isFirstEffect: 設定過一次後將 isFirstEffect 由 true 變為 false
		// * isPrepared: url 都有值，才算準備好，如果進到 localStorage 裡要把初始值蓋過去
		const isPrepared = every([queryPaymentType, queryChannelId])
		if (
			isPrepared &&
			isFirstEffect &&
			every([
				isDepositLocalStorageSavedChecked,
				!isEmpty(depositLocalStorageSavedInfo),
			])
		) {
			url = {
				pathname,
				query: {
					paymentType: queryPaymentType,
					channelId: depositLocalStorageSavedInfo?.channelId,
					sysId: depositLocalStorageSavedInfo?.sysId,
				},
			}
			toggleIsFirstEffect()
		}

		push(url, undefined, { shallow: true })
	}, [debouncedLoading, queryPaymentType, queryChannelId, querySysId])

	return {
		formMethods,
		custBankInfo,
		supportBanks,
		bankCardOptions,
		bankCardRequired,
		loading: debouncedLoading,
		onAutoDepositFormSubmit,
	}
}

export { useAutoDepositForm }
