// 桌機首頁元件 enum
export enum DesktopHomeComponentsEnum {
	// 首頁輪播
	CarouselBanner = 'CarouselBanner',
	// 跑馬燈
	Announcement = 'Announcement',
	// Promotion 跑馬燈
	PromotionSwiper = 'PromotionSwiper',
	// 歡迎資訊
	WelcomeInfo = 'WelcomeInfo',
	// LiveCasino
	LiveCasino = 'LiveCasino',
	// HotGame
	HotGame = 'HotGame',
	HotGameSport = 'HotGameSports',
	SlotGames = 'SlotGames',
	TableGames = 'TableGames',
	NewRelease = 'NewRelease',
	ProviderList = 'ProviderList',
	// HotGameCasino = 'HotGameCasino',
	// HotGameLiveCasino = 'HotGameLiveCasino',
	// HotGameFishing = 'HotGameFishing',
	// HotGameCockfighting = 'HotGameCockfighting',
	// HotGameLottery = 'HotGameLottery',
	// HotGameVirtualSports = 'HotGameVirtualSports',
	// HotGameP2P = 'HotGameP2P',
	// HotGameCrypto = 'HotGameCrypto',
	// HotGameESports = 'HotGameESports',
	// 配合廠商清單
	// ProviderBlock = 'ProviderBlock',
	// LuckyWheel
	// LuckyWheel = 'LuckyWheel',
	// 直播
	// LiveStreamingLink = 'LiveStreamingLink',
	// 左側快速下載標籤，底下 2 個新版用不到
	// QuickDownload = 'QuickDownload',
	// 右側社群連結
	// QuickLink = 'QuickLink',
	// 最近遊玩的遊戲，有登入才有
	// RecentlyViewGames = 'RecentlyViewGames',
	//  歐冠活動入口
	// EuropeCupEntry = 'EuropeCupEntry',
	// RetentionFloatingButton
	// Retention = 'Retention',
	// 2024巴黎奧運入口
	// OlympicEntry = 'OlympicEntry',
}

// 手機首頁元件 enum
export enum MobileHomeComponentsEnum {
	// 首頁輪播
	CarouselBanner = 'CarouselBanner',
	// 跑馬燈
	Announcement = 'Announcement',
	// LiveCasino
	LiveCasino = 'LiveCasino',
	// HotGame
	// HotGame = 'HotGame',
	HotGameSport = 'HotGameSports',
	SlotGames = 'SlotGames',
	TableGames = 'TableGames',
	NewRelease = 'NewRelease',
	ProviderList = 'ProviderList',
	// Promotion 跑馬燈
	PromotionSwiper = 'PromotionSwiper',

	// HotEventSwiper = 'HotEventSwiper',
	// HotGameSwiper = 'HotGameSwiper',
	// HotGameCasinoSwiper = 'HotGameCasinoSwiper',
	// JackPotSwiper = 'JackPotSwiper',
	// 最近遊玩的遊戲，有登入才有
	// RecentlyViewGames = 'RecentlyViewGames',
	// 登入才有
	// FriendReferral = 'FriendReferral',
	// Jackpot = 'Jackpot',
	// LuckyWheel = 'LuckyWheel',
	// LiveStreamingLink = 'LiveStreamingLink',
	// AppDownload = 'AppDownload',
	// PartnerBlock = 'PartnerBlock',
	// 代言人頭像
	FooterBlock = 'FooterBlock',
	// 簡易 Footer , 與 Footer 只可擇一添加
	SimpleFooter = 'SimpleFooter',
	//  歐冠活動入口
	// EuropeCupEntry = 'EuropeCupEntry',
	// RetentionFloatingButton
	// Retention = 'Retention',
	// 2024巴黎奧運入口
	// OlympicEntry = 'OlympicEntry',
}
