export enum LeaderBoardDayEnum {
	Today,
	Yesterday,
	LastTowDay,
	LstThreeDay,
}

export enum LeaderBoardGameTypeEnum {
	All,
	Sports,
	Slots,
	Casino,
}
export enum LeaderBoardTabsEnum {
	Event = 1,
	Ranking,
	GamePoint,
	Result,
}
