export const AREA_CODE: {
	[country: string]: {
		AreaCode: string
		Pattern: string
		Length: number
		MaxLength: number
		Name: string
		Abbr: string
	}
} = {
	MY: {
		AreaCode: '+60',
		Pattern: '[0-9]*',
		Length: 9,
		MaxLength: 50,
		Name: 'Malaysia',
		Abbr: 'MY',
	},
	TH: {
		AreaCode: '+66',
		Pattern: '[0-9]*',
		Length: 8,
		MaxLength: 50,
		Name: 'Thailand',
		Abbr: 'TH',
	},
	ID: {
		AreaCode: '+62',
		Pattern: '[0-9]*',
		Length: 10,
		MaxLength: 50,
		Name: 'Indonesia',
		Abbr: 'ID',
	},
	IN: {
		AreaCode: '+91',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'India',
		Abbr: 'IN',
	},
	BD: {
		AreaCode: '+880',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Bangladesh',
		Abbr: 'BD',
	},
	CS: {
		AreaCode: '+86',
		Pattern: '[0-9]*',
		Length: 11,
		MaxLength: 50,
		Name: 'China',
		Abbr: 'CS',
	},
	AF: {
		AreaCode: '+93',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Afghanistan',
		Abbr: 'AF',
	},
	AL: {
		AreaCode: '+355',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Albania',
		Abbr: 'AL',
	},
	DZ: {
		AreaCode: '+213',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Algeria',
		Abbr: 'DZ',
	},
	ANDO: {
		AreaCode: '+376',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Andorra',
		Abbr: 'ANDO',
	},
	AO: {
		AreaCode: '+244',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Angola',
		Abbr: 'AO',
	},
	AI: {
		AreaCode: '+1-264',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Anguilla',
		Abbr: 'AI',
	},
	ATG: {
		AreaCode: '+1-268',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Antigua and Barbuda',
		Abbr: 'ATG',
	},
	AR: {
		AreaCode: '+54',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Argentina',
		Abbr: 'AR',
	},
	AM: {
		AreaCode: '+374',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Armenia',
		Abbr: 'AM',
	},
	AT: {
		AreaCode: '+43',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Austria',
		Abbr: 'AT',
	},
	// AU: {
	// 	AreaCode: "+61",
	// 	Pattern: "[0-9]*",
	// 	Length: 1,
	// 	MaxLength: 50,
	// 	Name: "Australia",
	// 	Abbr: "AU"
	// },
	// AW: {
	// 	AreaCode: "+297",
	// 	Pattern: "[0-9]*",
	// 	Length: 1,
	// 	MaxLength: 50,
	// 	Name: "Aruba",
	// 	Abbr: "AW"
	// },
	AZ: {
		AreaCode: '+994',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Azerbaijians',
		Abbr: 'AZ',
	},
	BS: {
		AreaCode: '+1-242',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Bahamas',
		Abbr: 'BS',
	},
	BH: {
		AreaCode: '+973',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Bahrain',
		Abbr: 'BH',
	},

	BB: {
		AreaCode: '+1-246',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Barbados',
		Abbr: 'BB',
	},
	BY: {
		AreaCode: '+375',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Belarus',
		Abbr: 'BY',
	},
	BE: {
		AreaCode: '+32',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Belgium',
		Abbr: 'BE',
	},
	BZ: {
		AreaCode: '+501',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Belize',
		Abbr: 'BZ',
	},
	BJ: {
		AreaCode: '+229',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Benin',
		Abbr: 'BJ',
	},
	BM: {
		AreaCode: '+1-441',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Bermuda',
		Abbr: 'BM',
	},
	BT: {
		AreaCode: '+975',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Bhutan',
		Abbr: 'BT',
	},
	BO: {
		AreaCode: '+591',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Bolivia',
		Abbr: 'BO',
	},
	BA: {
		AreaCode: '+387',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Bosnia and Herzegovina',
		Abbr: 'BA',
	},
	BW: {
		AreaCode: '+267',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Botswana',
		Abbr: 'BW',
	},
	BR: {
		AreaCode: '+55',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Brazil',
		Abbr: 'BR',
	},
	BN: {
		AreaCode: '+673',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Brunei',
		Abbr: 'BN',
	},
	BG: {
		AreaCode: '+359',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Bulgaria',
		Abbr: 'BG',
	},
	BF: {
		AreaCode: '+226',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Burkina Faso',
		Abbr: 'BF',
	},
	BI: {
		AreaCode: '+257',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Burundi',
		Abbr: 'BI',
	},
	CV: {
		AreaCode: '+238',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Cape Verde',
		Abbr: 'CV',
	},
	KH: {
		AreaCode: '+855',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Cambodia',
		Abbr: 'KH',
	},
	CM: {
		AreaCode: '+237',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Cameroon',
		Abbr: 'CM',
	},
	CA: {
		AreaCode: '+1',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Canada',
		Abbr: 'CA',
	},
	// CD: {
	// 	AreaCode: "+243",
	// 	Pattern: "[0-9]*",
	// 	Length: 1,
	// 	MaxLength: 50,
	// 	Name: "Congo, The Democratic Republic of the",
	// 	Abbr: "CD"
	// },
	CF: {
		AreaCode: '+236',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Central African Republic',
		Abbr: 'CF',
	},
	TD: {
		AreaCode: '+235',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Chad',
		Abbr: 'TD',
	},
	CL: {
		AreaCode: '+56',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Chile',
		Abbr: 'CL',
	},
	CO: {
		AreaCode: '+57',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Columbia',
		Abbr: 'CO',
	},
	KM: {
		AreaCode: '+269',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Comoros',
		Abbr: 'KM',
	},
	CG: {
		AreaCode: '+242',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Congo',
		Abbr: 'CG',
	},
	KY: {
		AreaCode: '+1-345',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Cayman Islands',
		Abbr: 'KY',
	},
	CK: {
		AreaCode: '+682',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Cook Island',
		Abbr: 'CK',
	},
	CR: {
		AreaCode: '+506',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Costa Rica',
		Abbr: 'CR',
	},
	CI: {
		AreaCode: '+225',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Ivory Coast',
		Abbr: 'CI',
	},
	HR: {
		AreaCode: '+385',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Croatia',
		Abbr: 'HR',
	},
	CY: {
		AreaCode: '+357',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Cyprus',
		Abbr: 'CY',
	},
	CZ: {
		AreaCode: '+420',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Czech Republic',
		Abbr: 'CZ',
	},
	DJI: {
		AreaCode: '+253',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Djibouti',
		Abbr: 'DJI',
	},
	DO: {
		AreaCode: '+1-767',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Dominican',
		Abbr: 'DO',
	},
	EC: {
		AreaCode: '+593',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Ecuador',
		Abbr: 'EC',
	},
	EG: {
		AreaCode: '+20',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Egypt',
		Abbr: 'EG',
	},
	SV: {
		AreaCode: '+503',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'El Salvador',
		Abbr: 'SV',
	},
	GNQ: {
		AreaCode: '+240',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Equatorial Guinea',
		Abbr: 'GNQ',
	},
	EE: {
		AreaCode: '+372',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Estonia',
		Abbr: 'EE',
	},
	ET: {
		AreaCode: '+251',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Ethiopia',
		Abbr: 'ET',
	},
	FO: {
		AreaCode: '+298',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Faroe Island',
		Abbr: 'FO',
	},
	FJ: {
		AreaCode: '+679',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Fiji',
		Abbr: 'FJ',
	},
	FI: {
		AreaCode: '+358',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Finland',
		Abbr: 'FI',
	},
	GA: {
		AreaCode: '+241',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Gabon',
		Abbr: 'GA',
	},
	GM: {
		AreaCode: '+220',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Gambia',
		Abbr: 'GM',
	},
	GE: {
		AreaCode: '+995',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Georgia',
		Abbr: 'GE',
	},
	DE: {
		AreaCode: '+49',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Germany',
		Abbr: 'DE',
	},
	// ER: {
	// 	AreaCode: "+291",
	// 	Pattern: "[0-9]*",
	// 	Length: 1,
	// 	MaxLength: 50,
	// 	Name: "Eritrea",
	// 	Abbr: "ER"
	// },
	// FR: {
	// 	AreaCode: "+33",
	// 	Pattern: "[0-9]*",
	// 	Length: 1,
	// 	MaxLength: 50,
	// 	Name: "France",
	// 	Abbr: "FR"
	// },
	// GF: {
	// 	AreaCode: "+594",
	// 	Pattern: "[0-9]*",
	// 	Length: 1,
	// 	MaxLength: 50,
	// 	Name: "French Guiana",
	// 	Abbr: "GF"
	// },
	// PF: {
	// 	AreaCode: "+689",
	// 	Pattern: "[0-9]*",
	// 	Length: 1,
	// 	MaxLength: 50,
	// 	Name: "French Polynesia",
	// 	Abbr: "PF"
	// },
	GH: {
		AreaCode: '+233',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Ghana',
		Abbr: 'GH',
	},
	GR: {
		AreaCode: '+30',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Greece',
		Abbr: 'GR',
	},
	GL: {
		AreaCode: '+299',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Greenland',
		Abbr: 'GL',
	},
	GD: {
		AreaCode: '+1-473',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Grenada',
		Abbr: 'GD',
	},
	// GI: {
	// 	AreaCode: "+350",
	// 	Pattern: "[0-9]*",
	// 	Length: 1,
	// 	MaxLength: 50,
	// 	Name: "Gibraltar",
	// 	Abbr: "GI"
	// },
	GP: {
		AreaCode: '+590',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Guadeloupe',
		Abbr: 'GP',
	},
	GT: {
		AreaCode: '+502',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Guatemala',
		Abbr: 'GT',
	},
	GN: {
		AreaCode: '+224',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Guinea',
		Abbr: 'GN',
	},
	GW: {
		AreaCode: '+245',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Guinea-Bissau',
		Abbr: 'GW',
	},
	GY: {
		AreaCode: '+592',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Guyana',
		Abbr: 'GY',
	},
	HT: {
		AreaCode: '+509',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Haiti',
		Abbr: 'HT',
	},
	HN: {
		AreaCode: '+504',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Honduras',
		Abbr: 'HN',
	},
	HK: {
		AreaCode: '+852',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Hong Kong',
		Abbr: 'HK',
	},
	HU: {
		AreaCode: '+36',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Hungary',
		Abbr: 'HU',
	},
	IS: {
		AreaCode: '+354',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Iceland',
		Abbr: 'IS',
	},

	// IR: {
	// 	AreaCode: "+98",
	// 	Pattern: "[0-9]*",
	// 	Length: 1,
	// 	MaxLength: 50,
	// 	Name: "Iran, Islamic Republic Of",
	// 	Abbr: "IR"
	// },
	// IQ: {
	// 	AreaCode: "+964",
	// 	Pattern: "[0-9]*",
	// 	Length: 1,
	// 	MaxLength: 50,
	// 	Name: "Iraq",
	// 	Abbr: "IQ"
	// },
	IE: {
		AreaCode: '+353',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Ireland',
		Abbr: 'IE',
	},
	IL: {
		AreaCode: '+972',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Israel',
		Abbr: 'IL',
	},
	IT: {
		AreaCode: '+39',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Italy',
		Abbr: 'IT',
	},
	JM: {
		AreaCode: '+1-876',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Jamaica',
		Abbr: 'JM',
	},
	JP: {
		AreaCode: '+81',
		Pattern: '[0-9]*',
		Length: 10,
		MaxLength: 50,
		Name: 'Japan',
		Abbr: 'JP',
	},
	JO: {
		AreaCode: '+962',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Jordan',
		Abbr: 'JO',
	},
	KZ: {
		AreaCode: '+7',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Kazakhstan',
		Abbr: 'KZ',
	},
	KE: {
		AreaCode: '+254',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Kenya',
		Abbr: 'KE',
	},
	KI: {
		AreaCode: '+686',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Kiribati',
		Abbr: 'KI',
	},
	KR: {
		AreaCode: '+82',
		Pattern: '[0-9]*',
		Length: 9,
		MaxLength: 50,
		Name: 'South Korea',
		Abbr: 'KR',
	},
	KW: {
		AreaCode: '+965',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Kuwait',
		Abbr: 'KW',
	},
	KGZ: {
		AreaCode: '+996',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Kyrgyzstan',
		Abbr: 'KGZ',
	},
	LA: {
		AreaCode: '+856',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Laos',
		Abbr: 'LA',
	},
	LV: {
		AreaCode: '+371',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Latvia',
		Abbr: 'LV',
	},
	LB: {
		AreaCode: '+961',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Lebanon',
		Abbr: 'LB',
	},
	LS: {
		AreaCode: '+266',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Lesotho',
		Abbr: 'LS',
	},
	LR: {
		AreaCode: '+231',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Liberia',
		Abbr: 'LR',
	},
	LY: {
		AreaCode: '+218',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Libya',
		Abbr: 'LY',
	},
	LI: {
		AreaCode: '+423',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Liechtenstein',
		Abbr: 'LI',
	},
	LT: {
		AreaCode: '+370',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Lithuania',
		Abbr: 'LT',
	},
	LU: {
		AreaCode: '+352',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Luxembourg',
		Abbr: 'LU',
	},
	MO: {
		AreaCode: '+853',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Macau',
		Abbr: 'MO',
	},
	MG: {
		AreaCode: '+261',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Madagascar',
		Abbr: 'MG',
	},
	MW: {
		AreaCode: '+265',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Malawi',
		Abbr: 'MW',
	},
	MV: {
		AreaCode: '+960',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Madives',
		Abbr: 'MV',
	},
	ML: {
		AreaCode: '+223',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Mali',
		Abbr: 'ML',
	},
	MT: {
		AreaCode: '+356',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Malta',
		Abbr: 'MT',
	},
	MQ: {
		AreaCode: '+596',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Martinique',
		Abbr: 'MQ',
	},
	MR: {
		AreaCode: '+222',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Mauritania',
		Abbr: 'MR',
	},
	MU: {
		AreaCode: '+230',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Mauritius',
		Abbr: 'MU',
	},
	MX: {
		AreaCode: '+52',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Mexico',
		Abbr: 'MX',
	},
	MD: {
		AreaCode: '+373',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Moldova',
		Abbr: 'MD',
	},
	MC: {
		AreaCode: '+377',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Monaco',
		Abbr: 'MC',
	},
	MN: {
		AreaCode: '+976',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Mongolia',
		Abbr: 'MN',
	},
	MNE: {
		AreaCode: '+382',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Montenegro',
		Abbr: 'MNE',
	},
	MS: {
		AreaCode: '+1-664',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Montserrat',
		Abbr: 'MS',
	},
	MA: {
		AreaCode: '+212',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Morocco',
		Abbr: 'MA',
	},
	MZ: {
		AreaCode: '+258',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Mozambique',
		Abbr: 'MZ',
	},
	MM: {
		AreaCode: '+95',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Myanmar',
		Abbr: 'MM',
	},
	NA: {
		AreaCode: '+264',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Namibia',
		Abbr: 'NA',
	},
	NP: {
		AreaCode: '+977',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Nepal',
		Abbr: 'NP',
	},
	// NR: {
	// 	AreaCode: "+674",
	// 	Pattern: "[0-9]*",
	// 	Length: 1,
	// 	MaxLength: 50,
	// 	Name: "Nauru",
	// 	Abbr: "NR"
	// },

	// NL: {
	// 	AreaCode: "+31",
	// 	Pattern: "[0-9]*",
	// 	Length: 1,
	// 	MaxLength: 50,
	// 	Name: "Netherlands",
	// 	Abbr: "NL"
	// },
	NC: {
		AreaCode: '+687',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Nnew Caledonia',
		Abbr: 'NC',
	},
	NZ: {
		AreaCode: '+64',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'New Zealand',
		Abbr: 'NZ',
	},
	NIC: {
		AreaCode: '+505',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Nicaragua',
		Abbr: 'NIC',
	},
	NE: {
		AreaCode: '+227',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Niger',
		Abbr: 'NE',
	},
	NG: {
		AreaCode: '+234',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Nigeria',
		Abbr: 'NG',
	},
	MK: {
		AreaCode: '+389',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'North Macedonia',
		Abbr: 'MK',
	},
	NO: {
		AreaCode: '+47',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Norway',
		Abbr: 'NO',
	},
	OM: {
		AreaCode: '+968',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Oman',
		Abbr: 'OM',
	},
	PK: {
		AreaCode: '+92',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Pakistan',
		Abbr: 'PK',
	},
	PW: {
		AreaCode: '+680',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Palau',
		Abbr: 'PW',
	},
	PS: {
		AreaCode: '+970',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Palestine',
		Abbr: 'PS',
	},
	PA: {
		AreaCode: '+507',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Panama',
		Abbr: 'PA',
	},
	PG: {
		AreaCode: '+675',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Papua New Guinea',
		Abbr: 'PG',
	},
	PY: {
		AreaCode: '+595',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Paraguay',
		Abbr: 'PY',
	},
	PE: {
		AreaCode: '+51',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Peru',
		Abbr: 'PE',
	},
	// PN: {
	// 	AreaCode: "+870",
	// 	Pattern: "[0-9]*",
	// 	Length: 1,
	// 	MaxLength: 50,
	// 	Name: "Pitcairn",
	// 	Abbr: "PN"
	// },
	PL: {
		AreaCode: '+48',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Poland',
		Abbr: 'PL',
	},
	PT: {
		AreaCode: '+351',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Portugal',
		Abbr: 'PT',
	},
	PR: {
		AreaCode: '+1',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Puerto Rico',
		Abbr: 'PR',
	},
	QA: {
		AreaCode: '+974',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Qatar',
		Abbr: 'QA',
	},
	RE: {
		AreaCode: '+262',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Reunion',
		Abbr: 'RE',
	},

	RO: {
		AreaCode: '+40',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Romania',
		Abbr: 'RO',
	},
	RU: {
		AreaCode: '+7',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Russia',
		Abbr: 'RU',
	},
	RW: {
		AreaCode: '+250',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Rwanda',
		Abbr: 'RW',
	},
	KN: {
		AreaCode: '+1-869',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Saint Kitts and Nevis',
		Abbr: 'KN',
	},
	LC: {
		AreaCode: '+1-758',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Saint Lucia',
		Abbr: 'LC',
	},
	// VC: {
	// 	AreaCode: "+1-784",
	// 	Pattern: "[0-9]*",
	// 	Length: 1,
	// 	MaxLength: 50,
	// 	Name: "Saint Vincent and the Grenadines",
	// 	Abbr: "VC"
	// },
	// SM: {
	// 	AreaCode: "+378",
	// 	Pattern: "[0-9]*",
	// 	Length: 1,
	// 	MaxLength: 50,
	// 	Name: "San Marino",
	// 	Abbr: "SM"
	// },
	WS: {
		AreaCode: '+685',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Samoa',
		Abbr: 'WS',
	},
	ST: {
		AreaCode: '+239',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Sao Tome and Principe',
		Abbr: 'ST',
	},
	SA: {
		AreaCode: '+966',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Saudi Arabia',
		Abbr: 'SA',
	},
	SN: {
		AreaCode: '+221',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Senegal',
		Abbr: 'SN',
	},
	RS: {
		AreaCode: '+381',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Serbia',
		Abbr: 'RS',
	},
	SC: {
		AreaCode: '+248',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Seychelles',
		Abbr: 'SC',
	},
	SL: {
		AreaCode: '+232',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Sierra Loene',
		Abbr: 'SL',
	},
	SG: {
		AreaCode: '+65',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Singapore',
		Abbr: 'SG',
	},
	SK: {
		AreaCode: '+421',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Slovakia',
		Abbr: 'SK',
	},
	SI: {
		AreaCode: '+386',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Slovenia',
		Abbr: 'SI',
	},
	SB: {
		AreaCode: '+677',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Solomon Islands',
		Abbr: 'SB',
	},
	SO: {
		AreaCode: '+252',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Somalia',
		Abbr: 'SO',
	},
	ZA: {
		AreaCode: '+27',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'South Africa',
		Abbr: 'ZA',
	},
	SS: {
		AreaCode: '+211',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'South Sudan',
		Abbr: 'SS',
	},
	// ES: {
	// 	AreaCode: "+34",
	// 	Pattern: "[0-9]*",
	// 	Length: 1,
	// 	MaxLength: 50,
	// 	Name: "Spain",
	// 	Abbr: "ES"
	// },
	LK: {
		AreaCode: '+94',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Sri Lanka',
		Abbr: 'LK',
	},
	// SD: {
	// 	AreaCode: "+249",
	// 	Pattern: "[0-9]*",
	// 	Length: 1,
	// 	MaxLength: 50,
	// 	Name: "Sudan",
	// 	Abbr: "SD"
	// },
	SR: {
		AreaCode: '+597',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Suriname',
		Abbr: 'SR',
	},
	SZ: {
		AreaCode: '+268',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Swaziland',
		Abbr: 'SZ',
	},
	SE: {
		AreaCode: '+46',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Sweden',
		Abbr: 'SE',
	},
	CH: {
		AreaCode: '+41',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Switzerland',
		Abbr: 'CH',
	},
	// SY: {
	// 	AreaCode: "+963",
	// 	Pattern: "[0-9]*",
	// 	Length: 1,
	// 	MaxLength: 50,
	// 	Name: "Syrian Arab Republic",
	// 	Abbr: "SY"
	// },
	TW: {
		AreaCode: '+886',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Taiwan',
		Abbr: 'TW',
	},
	TJ: {
		AreaCode: '+992',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Tajikistan',
		Abbr: 'TJ',
	},
	TZ: {
		AreaCode: '+255',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Tanzania',
		Abbr: 'TZ',
	},
	TG: {
		AreaCode: '+228',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Togo',
		Abbr: 'TG',
	},
	TO: {
		AreaCode: '+676',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Tonga',
		Abbr: 'TO',
	},
	TT: {
		AreaCode: '+1-868',
		Pattern: '[0-9]*',
		Length: 8,
		MaxLength: 50,
		Name: 'Trinidad and Tobago',
		Abbr: 'TT',
	},
	TN: {
		AreaCode: '+216',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Tunisia',
		Abbr: 'TN',
	},
	TR: {
		AreaCode: '+90',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Turkey',
		Abbr: 'TR',
	},
	TKM: {
		AreaCode: '+993',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Turkmenistan',
		Abbr: 'TKM',
	},
	TC: {
		AreaCode: '+1-649',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Turk and Caicos',
		Abbr: 'TC',
	},
	UG: {
		AreaCode: '+256',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Uganda Island',
		Abbr: 'UG',
	},
	UA: {
		AreaCode: '+380',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Ukraine',
		Abbr: 'UA',
	},
	AE: {
		AreaCode: '+971',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'United Arab Emirates',
		Abbr: 'AE',
	},
	// UK: {
	// 	AreaCode: "+44",
	// 	Pattern: "[0-9]*",
	// 	Length: 1,
	// 	MaxLength: 50,
	// 	Name: "United Kingdom",
	// 	Abbr: "UK"
	// },
	UY: {
		AreaCode: '+598',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Uruguay',
		Abbr: 'UY',
	},
	UZ: {
		AreaCode: '+998',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Uzbekistan',
		Abbr: 'UZ',
	},
	VU: {
		AreaCode: '+678',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Vanuatu',
		Abbr: 'VU',
	},
	VE: {
		AreaCode: '+58',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Venezuela',
		Abbr: 'VE',
	},
	VN: {
		AreaCode: '+84',
		Pattern: '^[1-9]{1}[0-9]*$',
		Length: 9,
		MaxLength: 11,
		Name: 'Vietnam',
		Abbr: 'VN',
	},
	// WF: {
	// 	AreaCode: "+681",
	// 	Pattern: "[0-9]*",
	// 	Length: 1,
	// 	MaxLength: 50,
	// 	Name: "Wallis and Futuna",
	// 	Abbr: "WF"
	// },

	YE: {
		AreaCode: '+967',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Yemen',
		Abbr: 'YE',
	},
	// YU: {
	// 	AreaCode: "+38",
	// 	Pattern: "[0-9]*",
	// 	Length: 1,
	// 	MaxLength: 50,
	// 	Name: "Yugoslavia",
	// 	Abbr: "YU"
	// },
	ZM: {
		AreaCode: '+260',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Zambia',
		Abbr: 'ZM',
	},
	ZW: {
		AreaCode: '+263',
		Pattern: '[0-9]*',
		Length: 1,
		MaxLength: 50,
		Name: 'Zimbabwe',
		Abbr: 'ZW',
	},
}
