export const BANNER_IMAGE_LIST = {
	// TODO yashbet only
	en: 'NewindexCenterBanner_en.jpg',
	cs: 'NewindexCenterBanner_cs.jpg',
	id: 'NewindexCenterBanner_id.jpg',
	th: 'NewindexCenterBanner_th.jpg',
	hi: 'NewindexCenterBanner_hi.jpg',
	bd: 'NewindexCenterBanner_bd.jpg',
	my: 'NewindexCenterBanner_my.jpg',
	kh: 'NewindexCenterBanner_kh.jpg',
}
