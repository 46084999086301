export const PRODUCTS = {
	Soccer: {
		tpGameIdNo: 1,
		tpId: 41,
		tpGameType: 69,
		gameId: 1,
	},
	SabaSoccer: {
		tpGameIdNo: 2,
		tpId: 41,
		tpGameType: 69,
		gameId: 997,
	},
	Basketball: {
		tpGameIdNo: 3,
		tpId: 41,
		tpGameType: 69,
		gameId: 2,
	},
	Tennis: {
		tpGameIdNo: 4,
		tpId: 41,
		tpGameType: 69,
		gameId: 5,
	},
	Cricket: {
		tpGameIdNo: 5,
		tpId: 41,
		tpGameType: 69,
		gameId: 50,
	},
	ESportALL: {
		tpGameIdNo: 6,
		tpId: 41,
		tpGameType: 69,
		gameId: 43,
	},
	LOL: {
		tpGameIdNo: 7,
		tpId: 41,
		tpGameType: 69,
		gameId: 43,
		betType: 2,
	},
	Dota2: {
		tpGameIdNo: 8,
		tpId: 41,
		tpGameType: 69,
		gameId: 43,
		betType: 1,
	},
	KingOfGlory: {
		tpGameIdNo: 9,
		tpId: 41,
		tpGameType: 69,
		gameId: 43,
		betType: 4,
	},
	CSGO: {
		tpGameIdNo: 10,
		tpId: 41,
		tpGameType: 69,
		gameId: 43,
		betType: 3,
	},
	NumberGame: {
		tpGameIdNo: 11,
		tpId: 41,
		tpGameType: 70,
		gameId: 161,
	},
	OWVirtualSports: {
		tpGameIdNo: 12,
		tpId: 41,
		tpGameType: 71,
		gameId: -99,
	},
	Keno: {
		tpGameIdNo: 14,
		tpId: 71,
		tpGameType: 124,
		gameId: 0,
	},
	Lottery: {
		tpGameIdNo: 15,
		tpId: 72,
		tpGameType: 125,
		gameId: 0,
	},
	OWCardClub: {
		tpGameIdNo: 17,
		tpId: 41,
		tpGameType: 96,
		gameId: -99,
	},
	TableGame: {
		tpGameIdNo: 19,
		tpId: 73,
		tpGameType: 126,
		gameId: 0,
	},
	VirtualSports: {
		tpGameIdNo: 20,
		tpId: 41,
		tpGameType: 72,
		gameId: 192,
	},
	Vgaming: {
		tpGameIdNo: 24,
		tpId: 41,
		tpGameType: 95,
		gameId: 171,
	},
	SAGaming: {
		tpGameIdNo: 28,
		tpId: 2,
		tpGameType: 9,
		gameId: 2,
	},
	AsiaGaming: {
		tpGameIdNo: 29,
		tpId: 3,
		tpGameType: 8,
		gameId: 3,
	},
	DreamGaming: {
		tpGameIdNo: 30,
		tpId: 4,
		tpGameType: 10,
		gameId: 4,
	},
	PragmaticPlaySlot: {
		tpGameIdNo: 31,
		tpId: 5,
		tpGameType: 7,
		gameId: -1,
	},
	PragmaticPlayCasino: {
		tpGameIdNo: 32,
		tpId: 5,
		tpGameType: 16,
		gameId: 3701,
	},
	GamePlay: {
		tpGameIdNo: 33,
		tpId: 6,
		tpGameType: 11,
		gameId: -1,
	},
	GPIKeno: {
		tpGameIdNo: 34,
		tpId: 6,
		tpGameType: 88,
		gameId: 'keno',
	},
	VNLottery: {
		tpGameIdNo: 35,
		tpId: 6,
		tpGameType: 89,
		gameId: 'sode',
	},
	ThaiLottery: {
		tpGameIdNo: 36,
		tpId: 6,
		tpGameType: 90,
		gameId: 'thailottery',
	},
	Fast3: {
		tpGameIdNo: 37,
		tpId: 6,
		tpGameType: 91,
		gameId: 'fast3',
	},
	FishingWorld: {
		tpGameIdNo: 38,
		tpId: 7,
		tpGameType: 12,
		gameId: 10,
		name: 'Fishing World',
	},
	YGGDrassil: {
		tpGameIdNo: 39,
		tpId: 8,
		tpGameType: 14,
		gameId: -1,
	},
	Joker: {
		tpGameIdNo: 40,
		tpId: 9,
		tpGameType: 13,
		gameId: -1,
	},
	FishHaiba: {
		tpGameIdNo: 41,
		tpId: 9,
		tpGameType: 66,
		gameId: 'kk8nqm3cfwtng',
	},
	HappyFish5: {
		tpGameIdNo: 42,
		tpId: 9,
		tpGameType: 66,
		gameId: 'st5cmuqnaxycn',
	},
	MonsterAwaken: {
		tpGameIdNo: 43,
		tpId: 9,
		tpGameType: 66,
		gameId: '1jeqx59c7ztqg',
	},
	Spadegaming: {
		tpGameIdNo: 44,
		tpId: 10,
		tpGameType: 15,
		gameId: -1,
	},
	FishingWar: {
		tpGameIdNo: 45,
		tpId: 10,
		tpGameType: 31,
		gameId: 'F-SF02',
		name: 'Fishing War',
	},
	FishingGod: {
		tpGameIdNo: 46,
		tpId: 10,
		tpGameType: 31,
		gameId: 'F-SF01',
	},
	KingMaker: {
		tpGameIdNo: 47,
		tpId: 11,
		tpGameType: 52,
		gameId: '',
	},
	Allbet: {
		tpGameIdNo: 48,
		tpId: 12,
		tpGameType: 6,
		gameId: 0,
	},
	OneGamingPoker: {
		tpGameIdNo: 49,
		tpId: 13,
		tpGameType: 41,
		gameId: 0,
	},
	PGSoft: {
		tpGameIdNo: 50,
		tpId: 14,
		tpGameType: 17,
		gameId: -1,
	},
	AESexy: {
		tpGameIdNo: 51,
		tpId: 15,
		tpGameType: 18,
		gameId: 15,
	},
	CQ9: {
		tpGameIdNo: 52,
		tpId: 16,
		tpGameType: 29,
		gameId: -1,
	},
	Paradise: {
		tpGameIdNo: 53,
		tpId: 16,
		tpGameType: 44,
		gameId: 'AB3',
	},
	OneshotFishing: {
		tpGameIdNo: 54,
		tpId: 16,
		tpGameType: 44,
		gameId: 'AT01',
	},
	WE: {
		tpGameIdNo: 55,
		tpId: 18,
		tpGameType: 28,
		gameId: 0,
	},
	WMCasino: {
		tpGameIdNo: 56,
		tpId: 20,
		tpGameType: 32,
		gameId: 20,
	},
	Kiss918: {
		tpGameIdNo: 57,
		tpId: 21,
		tpGameType: 33,
		gameId: -1,
	},
	Mega888: {
		tpGameIdNo: 58,
		tpId: 22,
		tpGameType: 34,
		gameId: 0,
	},
	AstroTech: {
		tpGameIdNo: 59,
		tpId: 24,
		tpGameType: 39,
		gameId: -1,
	},
	DragonballFishing: {
		tpGameIdNo: 60,
		tpId: 24,
		tpGameType: 40,
		gameId: 'cmf0001',
	},
	TheTyrantAndThePrincess: {
		tpGameIdNo: 61,
		tpId: 24,
		tpGameType: 40,
		gameId: 'cmf0006',
	},
	FishingOfSaviors: {
		tpGameIdNo: 62,
		tpId: 24,
		tpGameType: 40,
		gameId: 'cmf0002',
	},
	// * 舊的 目前沒有在用
	PlaytechSlot: {
		tpGameIdNo: 63,
		tpId: 27,
		tpGameType: 46,
		gameId: -1,
	},
	// * 舊的 目前沒有在用
	PlaytechCasino: {
		tpGameIdNo: 64,
		tpId: 27,
		tpGameType: 48,
		gameId: 0,
	},
	n2LIVE: {
		tpGameIdNo: 65,
		tpId: 28,
		tpGameType: 45,
		gameId: '',
	},
	BtiSport: {
		tpGameIdNo: 66,
		tpId: 29,
		tpGameType: 49,
		gameId: '',
	},
	AMBPoker: {
		tpGameIdNo: 67,
		tpId: 30,
		tpGameType: 50,
		gameId: '',
	},
	AskmeSlot: {
		tpGameIdNo: 68,
		tpId: 30,
		tpGameType: 76,
		gameId: -1,
	},
	Nova88Exchange: {
		tpGameIdNo: 69,
		tpId: 31,
		tpGameType: 51,
		gameId: '',
	},
	JILI: {
		tpGameIdNo: 70,
		tpId: 32,
		tpGameType: 53,
		gameId: -1,
	},
	TCGaming: {
		tpGameIdNo: 71,
		tpId: 33,
		tpGameType: 54,
		gameId: '',
	},
	TCG: {
		tpGameIdNo: 72,
		tpId: 33,
		tpGameType: 55,
		gameId: '',
	},
	MicrogamingSlot: {
		tpGameIdNo: 73,
		tpId: 34,
		tpGameType: 57,
		gameId: -1,
	},
	MicrogamingCasino: {
		tpGameIdNo: 74,
		tpId: 34,
		tpGameType: 58,
		gameId: 0,
	},
	MPPoker: {
		tpGameIdNo: 75,
		tpId: 35,
		tpGameType: 60,
		gameId: '',
	},
	EvolutionGaming: {
		tpGameIdNo: 76,
		tpId: 36,
		tpGameType: 63,
		gameId: 0,
	},
	NetEnt: {
		tpGameIdNo: 77,
		tpId: 37,
		tpGameType: 64,
		gameId: -1,
	},
	RedTiger: {
		tpGameIdNo: 78,
		tpId: 38,
		tpGameType: 65,
		gameId: -1,
	},
	AdvantPlay: {
		tpGameIdNo: 79,
		tpId: 39,
		tpGameType: 67,
		gameId: -1,
	},
	Habanero: {
		tpGameIdNo: 80,
		tpId: 40,
		tpGameType: 68,
		gameId: -1,
	},
	SBOBET: {
		tpGameIdNo: 88,
		tpId: 42,
		tpGameType: 77,
		gameId: '',
	},
	SkywindSlot: {
		tpGameIdNo: 89,
		tpId: 43,
		tpGameType: 82,
		gameId: -1,
	},
	SkywindCasino: {
		tpGameIdNo: 90,
		tpId: 43,
		tpGameType: 84,
		gameId: 0,
	},
	OceanRuler: {
		tpGameIdNo: 91,
		tpId: 43,
		tpGameType: 85,
		gameId: 'sw_or',
	},
	FlyJet: {
		tpGameIdNo: 92,
		tpId: 43,
		tpGameType: 85,
		gameId: 'sw_fj',
	},
	CaiShenDaHai: {
		tpGameIdNo: 93,
		tpId: 43,
		tpGameType: 85,
		gameId: 'sw_cashdaha',
	},
	SimplePlay: {
		tpGameIdNo: 94,
		tpId: 44,
		tpGameType: 86,
		gameId: -1,
	},
	CryptoSports: {
		tpGameIdNo: 95,
		tpId: 45,
		tpGameType: 87,
		gameId: '',
	},
	WorldCup: {
		tpGameIdNo: 96,
		tpId: 41,
		tpGameType: 69,
		gameId: 2002,
	},
	YeebetCasino: {
		tpGameIdNo: 97,
		tpId: 46,
		tpGameType: 93,
		gameId: 0,
	},
	BigGaming: {
		tpGameIdNo: 98,
		tpId: 47,
		tpGameType: 94,
		gameId: 0,
	},
	TFGaming: {
		tpGameIdNo: 99,
		tpId: 48,
		tpGameType: 97,
		gameId: 0,
	},
	V8Poker: {
		tpGameIdNo: 100,
		tpId: 25,
		tpGameType: 47,
		gameId: 0,
	},
	'789SeDie': {
		tpGameIdNo: 101,
		tpId: 23,
		tpGameType: 37,
		gameId: 2,
		name: '789 Se Die',
	},
	TraditionalLottery: {
		tpGameIdNo: 102,
		tpId: 23,
		tpGameType: 35,
		gameId: 0,
		name: 'Traditional Lottery',
	},
	ES: {
		tpGameIdNo: 103,
		tpId: 49,
		tpGameType: 98,
		gameId: -1,
		name: 'ES',
	},
	// * 新的 PlayTech (Seamless Wallet)
	NewPlayTechSlot: {
		tpGameIdNo: 104,
		tpId: 51,
		tpGameType: 100,
		gameId: -1,
	},
	// * 新的 PlayTech (Seamless Wallet)
	NewPlayTechCasino: {
		tpGameIdNo: 105,
		tpId: 51,
		tpGameType: 101,
		gameId: 'bal',
	},
	Nextspin: {
		tpGameIdNo: 106,
		tpId: 50,
		tpGameType: 99,
		gameId: -1,
	},
	Volleyball: {
		tpGameIdNo: 107,
		tpId: 41,
		tpGameType: 69,
		gameId: 6,
	},
	Badminton: {
		tpGameIdNo: 108,
		tpId: 41,
		tpGameType: 69,
		gameId: 9,
	},
	IMESports: {
		tpGameIdNo: 109,
		tpId: 52,
		tpGameType: 102,
		gameId: 'ESPORTSBULL',
	},
	IMSports: {
		tpGameIdNo: 110,
		tpId: 52,
		tpGameType: 103,
		gameId: 'IMSB',
		name: 'IM Sports',
	},
	SPRIBE: {
		tpGameIdNo: 111,
		tpId: 53,
		tpGameType: 104,
		gameId: -1,
		name: 'SPRIBE',
	},
	Aviator: {
		tpGameIdNo: 112,
		tpId: 53,
		tpGameType: 104,
		gameId: 'imgame12001',
	},
	Mines: {
		tpGameIdNo: 113,
		tpId: 53,
		tpGameType: 104,
		gameId: 'imgame12002',
	},
	Goal: {
		tpGameIdNo: 114,
		tpId: 53,
		tpGameType: 104,
		gameId: 'imgame12003',
	},
	Dice: {
		tpGameIdNo: 115,
		tpId: 53,
		tpGameType: 104,
		gameId: 'imgame12004',
	},
	PlaynGO: {
		tpGameIdNo: 116,
		tpId: 54,
		tpGameType: 105,
		gameId: -1,
	},
	Pegasus: {
		tpGameIdNo: 117,
		tpId: 56,
		tpGameType: 107,
		gameId: -1,
	},
	ZombieParty: {
		tpGameIdNo: 118,
		tpId: 10,
		tpGameType: 31,
		gameId: 'F-ZP01',
	},
	AlienHunter: {
		tpGameIdNo: 119,
		tpId: 10,
		tpGameType: 31,
		gameId: 'F-AH01',
		name: 'Alien Hunter',
	},
	Spaceman: {
		tpGameIdNo: 120,
		tpId: 5,
		tpGameType: 7,
		gameId: 1301,
	},
	Crash: {
		tpGameIdNo: 121,
		tpId: 39,
		tpGameType: 67,
		gameId: 'Crash',
	},
	GoRush: {
		tpGameIdNo: 122,
		tpId: 32,
		tpGameType: 53,
		gameId: 224,
	},
	FaChai: {
		tpGameIdNo: 123,
		tpId: 55,
		tpGameType: 106,
		gameId: -1,
	},
	BNG: {
		tpGameIdNo: 124,
		tpId: 57,
		tpGameType: 108,
		gameId: -1,
	},
	GrandDragon: {
		tpGameIdNo: 125,
		tpId: 58,
		tpGameType: 110,
		gameId: 'HL',
	},
	NoLimitCity: {
		tpGameIdNo: 126,
		tpId: 59,
		tpGameType: 111,
		gameId: -1,
	},
	AmigoGaming: {
		tpGameIdNo: 127,
		tpId: 60,
		tpGameType: 112,
		gameId: -1,
		name: 'Amigo Gaming',
	},
	MaxGame: {
		tpGameIdNo: 128,
		tpId: 61,
		tpGameType: 113,
		gameId: 0,
	},
	'93Connect': {
		tpGameIdNo: 129,
		tpId: 62,
		tpGameType: 114,
		gameId: 'LOBBY',
	},
	GACockFighting: {
		tpGameIdNo: 130,
		tpId: 63,
		tpGameType: 115,
		gameId: -1,
	},
	WonCasino: {
		tpGameIdNo: 131,
		tpId: 64,
		tpGameType: 116,
		gameId: 0,
	},
	SabaPlay: {
		tpGameIdNo: 134,
		tpId: 66,
		tpGameType: 119,
		gameId: 0,
	},
	UuSlots: {
		tpGameIdNo: 135,
		tpId: 67,
		tpGameType: 120,
		gameId: '-1',
	},
	Live22: {
		tpGameIdNo: 136,
		tpId: 68,
		tpGameType: 121,
		gameId: '-1',
	},
	FastSpin: {
		tpGameIdNo: 137,
		tpId: 69,
		tpGameType: 122,
		gameId: '-1',
	},

	// * Footer 靜態遊戲入口
	BaseBall: {
		tpGameIdNo: 0, // DB 沒有定義 // 先訂 0 build 的時候會不給過
		gameId: 8,
		tpId: 41,
		name: 'baseball',
		tpGameType: 69,
	},
	Football: {
		tpGameIdNo: 0, // DB 沒有定義 // 先訂 0 build 的時候會不給過
		gameId: 3,
		tpId: 41,
		name: 'football',
		tpGameType: 69,
	},
	Hockey: {
		tpGameIdNo: 0, // DB 沒有定義 // 先訂 0 build 的時候會不給過
		gameId: 4,
		tpId: 41,
		name: 'hockey',
		tpGameType: 69,
	},
} as const

/**
 * 所有產品(包含所有品牌)
 * 按照 tpGameIdNo 排序
 */
export interface IProduct {
	tpGameIdNo: number | undefined
	tpId: number
	tpGameType: number
	gameId?: number | string
	betType?: number
	name?: string
}

export type IProducts = typeof PRODUCTS
