export const paths = {
	home: {
		root: '/',
	},
	login: {
		root: '/login',
	},
	forgetPassword: {
		root: '/forget-password',
	},
	join: {
		root: '/join',
	},
	deposit: {
		root: '/deposit',
	},
	history: {
		root: '/history',
	},
	referralHistory: {
		root: '/referral-history',
	},
	depositHistory: {
		root: '/deposit-history',
	},
	withdrawalHistory: {
		root: '/withdrawal-history',
	},
	transferHistory: {
		root: '/transfer-history',
	},
	financialLog: {
		root: '/financial-log',
	},
	transferFunds: {
		root: '/transfer-funds',
	},
	withdrawal: {
		root: '/withdrawal',
	},
	eWalletWithdrawal: {
		root: '/eWallet-withdrawal',
	},
	cryptoWithdrawal: {
		root: '/crypto-withdrawal',
	},
	kycWaiting: {
		root: '/kyc-waiting',
	},
	kyc: {
		root: '/kyc',
	},
	message: {
		root: '/message',
	},
	profile: {
		root: '/profile',
	},
	statement: {
		root: '/statement',
	},
	wallet: {
		root: '/wallet',
	},
	eWallet: {
		root: '/eWallet',
	},
	cryptoWallet: {
		root: '/crypto-wallet',
	},
	referral: {
		root: '/referral',
	},
	changePassword: {
		root: '/change-password',
	},
	selfExclusion: {
		root: '/self-exclusion',
	},
	bankInfo: {
		root: '/bank-info',
	},
	appDownload: {
		root: '/app-download',
	},
	promotion: {
		root: '/promotion',
	},
	vip: {
		root: '/vip',
	},
	sports: {
		root: '/sports',
	},
	eSport: {
		root: '/eSport',
	},
	liveCasino: {
		root: '/live-casino',
	},
	slots: {
		root: '/slots',
	},
	oneGame: {
		root: '/poker',
	},
	fishing: {
		root: '/fishing',
	},
	fourD: {
		root: '/4D',
	},
	game: {
		root: '/game',
	},
	lottery: {
		root: '/lottery',
	},
	numberGame: {
		root: '/numberGame',
	},
	keno: {
		root: '/keno',
	},
	setting: {
		root: '/setting',
	},
	myAccount: {
		root: '/my-account',
	},
	leaderBoard: {
		root: '/leaderBoard',
	},
	announcement: {
		root: '/announcement',
	},
	rewards: {
		root: '/rewards',
	},
	newsInfo: {
		root: '/news-info',
	},
	luckyWheel: {
		root: '/luckyWheel',
	},
	gameProvider: {
		root: '/game-provider',
	},
	europeCup: {
		root: '/euro-cup',
	},
	milestones: {
		root: '/milestones',
	},
	retention: {
		root: '/retention',
	},
	olympic: {
		root: '/olympic',
	},
	survey: {
		root: '/survey',
	},
} as const

export type IRootpath = (typeof paths)[keyof typeof paths]['root']
