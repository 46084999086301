export enum AutoTrasferStatusEnum {
	open = 1,
	close = 2,
}

export enum TransferTypeEnum {
	transfer = 1,
	restoreAll = 2,
}

export const TransferWalletTpId = {
	mainWallet: 0,
}

export enum TranferHistoryStatusEnum {
	success = 0,
	fail = 1,
}
